// src/SemiCircleTabs.js
import React, { useEffect, useState } from "react";
import { Watermark } from "@hirohe/react-watermark";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./Incentives.scss";
import {
  getHierarchyData,
  getIncentivesData,
  setIncentiveActiveTab,
} from "../../redux/reducers/Incentives";
import { useDispatch, useSelector } from "react-redux";
import { FaMoon, FaSun } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import nodata from "../../assets/nodata.json";
import Lottie from "react-lottie-player";
import Chart from "react-apexcharts";
import IncentivesLoader from "../../components/IncentivesLoader/IncentivesLoader";
import { FaFilter } from "react-icons/fa";
import Select from "react-select";
import incentives from "../../assets/incentive.png";
import SlotCounter from "react-slot-counter";
import incentiveacc from "../../assets/incentives-mobile.png";
import incentiveachevied from "../../assets/incentives-achevied.png";
import incentivemonth from "../../assets/incentives-month.png";
import incentivetarget from "../../assets/incentive-target.png";
import incentiveamount from "../../assets/incentive-amount.png";
import incentivespot from "../../assets/incentive-spot.png";
import incentiveovr from "../../assets/incentive-ovr.png";
import incentivemobile from "../../assets/incentive-mobiles.png";
import incentiveflashguard from "../../assets/incentives-flashguard.png";
import incentivehc from "../../assets/incentive-hc.png";
import incentiveprofit from "../../assets/incentive-profit.png";
import incentiveprimiun from "../../assets/incentives-primium.png";
import incentivestv from "../../assets/incentives-tv.png";

const SemiCircleTabs = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [mainACtiveIndex, setMainActiveIndex] = useState(0);
  const [isdarkmode, setIsdarkmode] = useState(true);
  const [loader, setLoader] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedStore, setSelectedStore] = useState("");
  const [selectedAsm, setSelectedAsm] = useState("");
  const [selectedEmployee,setSelectedEmployee]=useState("")
  const activeTab = useSelector((state) => state.incentive.activeTab);
  const incentivesData = useSelector((state) => state.incentive.incentivesData);
  const hirerachyData = useSelector((state) => state.incentive.hirerachyData);

  const dispatch = useDispatch();
  let location = useLocation();
  let params = new URLSearchParams(location?.search);
  let empCode = params.get("id");
  const [subTabName, setSubTabName] = useState("All");

  const tabs = [
    "All",
    "OVR",
    "FG",
    "HC",
    "SPT",
    "Acc",
    "QT",
    "TV",
    "PR",
    "A+S",
  ];

  const mainTabs = ["FTD", "MTD", "PREVIOUS MONTH"];
  const [mainActiveTab, setActiveMainTab] = useState("FTD");

  // const empCode = "HM0452";
  const handleTabClick = (index) => {
    setActiveIndex(index);
  };
  const handleMainTabClick = (index) => {
    setMainActiveIndex(index);
  };

  const handleCheckboxChange = () => {
    setIsdarkmode(!isdarkmode);
  };

  const getIncentiveAmount = () => {
    if (incentivesData?.[0]?.incentives?.length > 0) {
      return incentivesData?.[0]?.incentives?.map(
        (item) => item.incentive_amount
      );
    }
  };

  const getAchievedtarget = () => {
    if (incentivesData) {
      return incentivesData?.map((item, i) => {
        return item?.achieved_target;
      });
    }
  };

  const Allseries = [
    {
      name: "Incentives",
      data: getIncentiveAmount(),
    },
  ];

  const getCategories = () => {
    if (incentivesData?.[0]?.incentives?.length > 0) {
      return incentivesData?.[0]?.incentives?.map((item) => item.target_type);
    }
  };

  const tabNames = [
    "All",
    "Oppo / Vivo / Realme",
    "Flash Gard",
    "Happi Care",
    "Spot Models",
    "Accessories",
    "QT",
    "TV[Acer & Sansui]",
    "PROFIT",
    "PREMIUM",
  ];

  const tabAbbreviations = {
    "Oppo / Vivo / Realme": "OVR",
  };
  const tabAbbreviation = () => tabAbbreviations[tabname()] || tabname();

  const tabname = () => {
    return tabNames[activeIndex] || "Unknown";
  };
  const productcategeries = () => {
    return tabs[activeIndex] || "Unknown";
  };

  const Alloptions = {
    grid: {
      show: false,
      borderColor: "#90A4AE",
      strokeDashArray: 0,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    chart: {
      height: 250,
      type: "bar",
      toolbar: {
        show: false, // This hides the graph menu/context menu
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        distributed: true,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + "";
      },
      offsetY: -20,
      style: {
        fontSize: "9px",
        colors: ["#b3b1b1"],
      },
    },

    xaxis: {
      categories: getCategories(),
      position: "bottom",
      axisBorder: {
        show: true,
      },

      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0,
            opacityTo: 0,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
      labels: {
        style: {
          colors: "#b3b1b1",
          fontSize: "9px",
        },
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val + "";
        },
      },
    },
    legend: {
      show: false,
    },
    title: {
      text: "",
      floating: true,
      offsetY: 330,
      align: "center",
      style: {
        color: "#444",
      },
    },
  };

  const series = [getAchievedtarget()];

  // const options = {
  //   chart: {
  //     height: 1050,
  //     type: "radialBar",
  //   },
  //   plotOptions: {
  //     radialBar: {
  //       offsetY: 0,
  //       startAngle: 0,
  //       endAngle: 270,
  //       hollow: {
  //         margin: 2,
  //         size: "20%",
  //       },
  //       track: {
  //         show: true,
  //         background: "#e5dddd",
  //         //#FFF
  //         // #1c252e
  //         strokeWidth: "97%",
  //       },
  //       dataLabels: {
  //         total: {
  //           show: true,
  //           label: "Total",
  //           color: "white",
  //         },
  //         name: {
  //           show: false,
  //         },
  //         value: {
  //           show: false,
  //         },
  //       },
  //       barLabels: {
  //         enabled: false,
  //         useSeriesColors: true,
  //         offsetX: -18,
  //         offsetY: 0,
  //         fontWeight: 500,
  //         fontSize: "12px",
  //         formatter: function (seriesName, opts) {
  //           return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
  //         },
  //       },
  //     },
  //   },
  //   colors: ["#ffa658"],
  //   labels: [productcategeries()],

  //   responsive: [
  //     {
  //       breakpoint: 480,
  //       options: {
  //         legend: {
  //           show: false,
  //         },
  //       },
  //     },
  //   ],
  // };

  const options = {
    series: [75],
    chart: {
      height: 270,
      type: "radialBar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
        hollow: {
          margin: 0,
          size: "70%",
          background: "transparent",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: true,
            top: 1,
            left: 0,
            blur: 2,
            opacity: 0.2,
          },
        },
        track: {
          background: "#e5dddd",
          strokeWidth: "67%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35,
          },
        },

        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: "#b3b1b1",
            fontSize: "17px",
          },
          value: {
            formatter: function (val) {
              return parseInt(val);
            },
            color: "#b3b1b1",
            fontSize: "24px",
            show: true,
          },
        },
      },
    },
    fill: {
      colors: "#ED9D0B",
    },
    stroke: {
      show: true,
      curve: "straight",
      lineCap: "round",
      colors: undefined,
      width: 1,
      dashArray: 0,
    },
    labels: ["Percent"],
  };

  useEffect(() => {
    getData("All", empCode, "FTD");
    document.title='Incentives'
  }, [empCode]);

  const getData = (type, code, tab) => {
    setLoader(true);
    let month = new Date().getMonth() + 1;
    let year = new Date().getFullYear();
    if (type == "All") type = "consolidated";
    if (type == "FG") type = "FlashGard";
    if (type == "SPT") type = "SPOT";
    if (type == "QT") type = "Mobiles";
    if (type == "TV Acer&sansui") type = "TV";
    if (type == "PR") type = "PROFIT";
    if (type == "A+S") type = "PREMIUM";

    if (tab == "PREVIOUS MONTH") {
      month = month - 1;
      tab = "MTD";
    }
    if (month == 1) {
      month = 12;
      year = year - 1;
    }

    let payload = {
      type,
      emp_id: code,
      tab: tab,
      month,
      year,
    };
    dispatch(getHierarchyData(code));
    dispatch(getIncentivesData(payload)).then((res) => setLoader(false));
  };

  const currencyFormat = (number, decimalPlaces = 2) => {
    let roundedNumber = Number(number?.toFixed(decimalPlaces));
    if(!roundedNumber){
      roundedNumber=0;
    }
    const result = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(roundedNumber);
    console.log("result",result)
    console.log("result-type",typeof result)
    return result;
  };

  const getDropDownData = (type) => {
    if (type == "store")
      return hirerachyData?.[0]?.store_heads?.map((item) => ({
        value: item?.emp_code,
        label: item?.store_name,
      }));
    if (type == "ASM")
      return hirerachyData?.[0]?.asm?.map((item) => ({
        value: item?.emp_code,
        label: item?.asm_name,
      })).filter(item=>item.value&&item?.label);
    if (type == "statehead") {
      return hirerachyData?.[0]?.state_heads?.map((item) => ({
        value: item?.region,
        label: item?.region,
      }));
    }
    if(type=='employee'){
      return hirerachyData?.[0]?.store_employees?.map((item) => ({
        value: item?.emp_code,
        label: item?.emp_name,
      })).filter(item=>item.value&&item?.label);
    }
  };

  const handleSelectedStore = (val) => {
    let code = val?.value;
    if (!val) {
      code = empCode;
    }
    setSelectedStore(val);
    getData(subTabName, code, mainActiveTab);
  };

  const handleSelectedEmployee=(val)=>{
    let code = val?.value;
    if (!val) {
      code = empCode;
    }
    setSelectedEmployee(val);
    getData(subTabName, code, mainActiveTab);
  };
  

  const handleSelectedAsm = (val) => {
    setSelectedAsm(val);
    let code = val?.value;
    if (!val) {
      code = empCode;
    }
    getData(subTabName, code, mainActiveTab);
  };

  const allincentivesimg = (src) => {
    if (src == "Acc") return incentiveacc;
    if (src == "SPOT") return incentivespot;
    if (src == "OVR") return incentiveovr;
    if (src == "Mobiles") return incentivemobile;
    if (src == "HC") return incentivehc;
    if (src == "FlashGard") return incentiveflashguard;
    if (src == "PROFIT") return incentiveprofit;
    if (src == "PREMIUM") return incentiveprimiun;
    if (src == "TV") return incentivestv;
  };

  const data = () => {
    return (
      <Container fluid>
        <Row>
          {loader ? (
            <Col>
              <IncentivesLoader />
            </Col>
          ) : incentivesData?.length > 0 ? (
            <Col sm={12} className="mt-2">
              <div className="h-ctrl">
                <h6 className="mb-2 title">{tabname()}</h6>
                <Card className="p-3 title-crd">
                  <div className="d-flex align-items-center justify-content-around gap-2 slot-counter-div">
                    <div className="inc-img">
                      <img
                        src={incentives}
                        alt="incentives"
                        title="incentives"
                      />
                    </div>
                    <div className="text-center">
                      <h5 className="mb-0">Incentives</h5>
                      <h6 className="d-flex m-0 align-items-baseline gap-1 fst-italic">
                        <SlotCounter
                          value={`${currencyFormat(
                            incentivesData?.[0]?.incentive_amount
                          )}`}
                          speed={1.1}
                          duration={5}
                          direction={"bottom-top" | "top-bottom"}
                        />
                        {mainACtiveIndex == 0 ? ` Today` : ` This Month`}
                      </h6>
                    </div>
                  </div>
                </Card>

                {incentivesData?.map((item, i) => {
                  return activeIndex == 0 && item.incentives.length > 0 ? (
                    <>
                      <Card className="p-3 mt-2 bar-chart">
                          <Chart
                            options={Alloptions}
                            series={Allseries}
                            type="bar"
                            height={270}
                          />
                        <p className="m-0 text-center">
                          Category Based Rewards
                        </p>
                      </Card>
                      <div className="swiper-div" key={i}>
                        <Row className="gx-2">
                          {item?.incentives.map((item) => {
                            return (
                              <Col xs={6} sm={6}>
                                <Card className="p-3 mt-2 ">
                                  <div className="d-flex align-items-end gap-2 flex-row">
                                    <div className="icon">
                                      <img
                                        src={allincentivesimg(
                                          item?.target_type
                                        )}
                                        alt="mobile-img"
                                        title="mobile-img"
                                      />
                                    </div>
                                    <h6 className="m-0 mb-1">
                                      {item?.target_type == "Mobiles"
                                        ? "QT"
                                        : item?.target_type}
                                    </h6>
                                  </div>
                                  <div className="content text-center w-100">
                                    <div className="incentive-amount">
                                      <SlotCounter
                                        value={currencyFormat(
                                          item?.incentive_amount
                                        )}
                                        speed={1}
                                        duration={3}
                                        direction={"bottom-top" | "top-bottom"}
                                      />
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    </>
                  ) : (
                    item?.emp_code == empCode && (
                      <div className="swiper-div">
                        <Card className="p-3 mt-2">
                          <Chart
                            options={options}
                            series={series}
                            type="radialBar"
                          />
                          <p className="text-center">{`${tabAbbreviation()} Achieved Target`}</p>
                        </Card>
                        <Row className="gx-2">
                          <Col xs={6}>
                            <Card className="p-3 mt-2 inc">
                              <div className="d-flex align-items-end gap-3 justify-content-center">
                                <div className="icon inc-icon">
                                  <img
                                    src={incentiveachevied}
                                    title="incentiveachevied"
                                    alt="incentiveachevied"
                                  />
                                </div>
                                <h6>AT</h6>
                              </div>
                              <div className="text-center">
                                <h5 className="m-0 mt-1">
                                  {item.flag=="QTY"?item?.achieved_target:currencyFormat(item?.achieved_target)}
                                </h5>
                              </div>
                            </Card>
                          </Col>
                          <Col xs={6}>
                            <Card className="p-3 mt-2 inc">
                              <div className="d-flex align-items-end gap-2 justify-content-center">
                                <div className="icon inc-icon">
                                  <img
                                    src={incentiveamount}
                                    title="incentiveamount"
                                    alt="incentiveamount"
                                  />
                                </div>
                                <h6>IA</h6>
                              </div>
                              <div className="text-center">
                                <h5 className="m-0 mt-1">
                                  {currencyFormat(item?.incentive_amount)}
                                </h5>
                              </div>
                            </Card>
                          </Col>
                          {
                            item.ftd_target>=0&&<Col xs={6}>
                            <Card className="p-3 mt-2 inc">
                              <div className="d-flex align-items-end gap-3 justify-content-center">
                                <div className="icon inc-icon">
                                  <img
                                    src={incentivemonth}
                                    title="incentivemonth"
                                    alt="incentivemonth"
                                  />
                                </div>
                                <h6>FTD</h6>
                              </div>
                              <div className="text-center">
                                <h5 className="m-0 mt-1">
                                  {item?.flag=="QTY"?item.ftd_target:currencyFormat(item.ftd_target)}
                                </h5>
                              </div>
                            </Card>
                          </Col>
                          }
                          {
                            item?.mtd_target>=0&&<Col xs={6}>
                            <Card className="p-3 mt-2 inc">
                              <div className="d-flex align-items-end gap-3 justify-content-center">
                                <div className="icon inc-icon">
                                  <img
                                    src={incentivemonth}
                                    title="incentivemonth"
                                    alt="incentivemonth"
                                  />
                                </div>
                                <h6>MTD</h6>
                              </div>
                              <div className="text-center">
                                <h5 className="m-0 mt-1">
                                  {item?.flag=="QTY"?item?.mtd_target
                                    :currencyFormat(item.mtd_target)
                                    }
                                </h5>
                              </div>
                            </Card>
                          </Col>
                          }
                          <Col xs={6}>
                            <Card className="p-3 mt-2 inc">
                              <div className="d-flex align-items-end gap-2 justify-content-center">
                                <div className="icon inc-icon">
                                  <img
                                    src={incentivetarget}
                                    title="incentivetarget"
                                    alt="incentivetarget"
                                  />
                                </div>
                                <h6>TT</h6>
                              </div>
                              <div className="text-center">
                                <h5 className="m-0 mt-1">
                                  {item?.flag=="QTY"?item?.target||0:item?.target||0}
                                </h5>
                              </div>
                            </Card>
                          </Col>
                        </Row>
                      </div>
                    )
                  );
                })}
              </div>
            </Col>
          ) : (
            <div className="lottie d-flex align-items-center justify-content-center">
              <Lottie
                loop
                animationData={nodata}
                play
                style={{ width: "100%" }}
              />
            </div>
          )}
        </Row>
      </Container>
    );
  };
  const subTabs = () => {
    return (
      <Row>
        <Col className="mt-2">
          <div className="paar">
            {/* Tabs */}

            <div className="tabs all mt-1 gap-2">
              {tabs.map((item, index) => (
                <button
                  key={index}
                  className={`tab ${activeIndex === index ? "active" : ""}`}
                  onClick={() => {
                    handleTabClick(index);
                    getData(item, empCode, mainActiveTab);
                    setSubTabName(item);
                  }}
                >
                  {item}
                </button>
              ))}
            </div>
            {data()}
          </div>
        </Col>
      </Row>
    );
  };
  return (
    <div className={`inentives ${isdarkmode && "darkmode"}`}>
      <div className="checkbox-parent d-flex align-items-center gap-2">
        {isdarkmode ? (
          <FaSun className="fa-moon" onClick={handleCheckboxChange} />
        ) : (
          <FaMoon className="fa-sun" onClick={handleCheckboxChange} />
        )}
        <FaFilter
          className="fa-filter"
          onClick={() => setShowFilter(!showFilter)}
        />
      </div>

      <Watermark
        text={`-${empCode}-`}
        textSize={18}
        textColor={`${isdarkmode ? "white" : "#000000"}`}
        lineHeight="1.5rem"
        opacity={isdarkmode ? 0.03 : 0.05}
      >
        <div className="watermark">
          <Container fluid>
            <Row className="justify-content-center mb-2">
              <Col className="mt-2" lg={3}>
                <div className="paar">
                  <div className="tabs mobil-tabs mt-1">
                    {mainTabs.map((item, index) => (
                      <button
                        key={index}
                        className={`tab ${
                          mainACtiveIndex === index ? "active" : ""
                        }`}
                        onClick={() => {
                          handleMainTabClick(index);
                          setActiveMainTab(item);
                          getData(subTabName, empCode, item);
                        }}
                      >
                        {item}
                      </button>
                    ))}
                  </div>
                </div>
                {showFilter && (
                  <>
                    <div className="mt-2 z-4 position-relative">
                      {hirerachyData?.[0]?.state_heads?.length > 0 && (
                        <Select
                          name="statehead"
                          options={getDropDownData("statehead")}
                          placeholder={getDropDownData("statehead")?.[0]?.label}
                          isDisabled={true}
                        />
                      )}
                    </div>
                    {hirerachyData?.[0]?.asm?.length > 0 && (
                      <div className="mt-2 z-3 position-relative">
                        <Select
                          name="asm"
                          options={getDropDownData("ASM")}
                          onChange={handleSelectedAsm}
                          value={selectedAsm}
                          placeholder={
                            getDropDownData("statehead").length == 0
                              ? getDropDownData("ASM")?.[0]?.label
                              : "Select ASM"
                          }
                          isClearable={true}
                          isDisabled={getDropDownData("statehead").length == 0}
                        />
                      </div>
                    )}



                    {hirerachyData?.[0]?.store_heads?.length > 0 && (
                      <div className="mt-2 z-2 position-relative">
                        <Select
                          name="Select Store"
                          options={getDropDownData("store")}
                          onChange={handleSelectedStore}
                          value={selectedStore}
                          placeholder={getDropDownData("statehead").length == 0&&getDropDownData("store").length==1?hirerachyData?.[0]?.store_heads?.[0]?.emp_name:"Select Store"}
                          isClearable={true}
                          isDisabled={getDropDownData("statehead").length == 0&&getDropDownData("store").length==1}
                        />
                      </div>
                    )}
                      {hirerachyData?.[0]?.store_employees?.length > 0 && (
                      <div className="mt-2 z-1 position-relative">
                        <Select
                          name="color"
                          options={getDropDownData("employee")}
                          onChange={handleSelectedEmployee}
                          value={selectedEmployee}
                          placeholder={"Select Employee"}
                          isClearable={true}
                        />
                      </div>
                    )}
                  </>
                )}
                {subTabs()}
              </Col>
            </Row>
          </Container>
        </div>
      </Watermark>
    </div>
  );
};
export default SemiCircleTabs;
