import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rules from "./reducers/rules";
import stocktransfer from "./reducers/stocktranfer"
import profitability from "./reducers/profitability";
import incentives from './reducers/Incentives'

const persistConfig = {
  key: "persist",
  storage,
  whitelist: ["auth"], // which reducer want to store
};

const rootReducer = combineReducers({
  rules: rules,
  stocktransfer:stocktransfer,
  profitability:profitability,
  incentive:incentives
});

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: pReducer,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, }),
});
