import React, { useEffect, useState } from "react";
import 'react-data-grid/lib/styles.css';
import DataGrid from 'react-data-grid';
import { FaPencilAlt } from "react-icons/fa";
import { Form } from "react-bootstrap";
import { useNavigate,useParams,useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getIndividualRule,changeRuleStatus,setUserId} from "../redux/reducers/rules";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";


// import Form from 'react-bootstrap/Form';


// const StatusFormatter = ({ row }) => {
//     console.log("ROW", row)

//     const [isChecked, setIsChecked] = useState(row.status);


//     const handleToggle = (e) => {
//         // Add your logic to handle checkbox change here
//         console.log(`Checkbox checked for row with ID: ${row.id}`);
//         console.log('New value:', e.target.checked);
//         setIsChecked(e.target.checked)
//     };

//     return (
//         <Form.Check
//             type="switch"
//             id="toggle-switch"
//             label=""
//             checked={isChecked}
//             onChange={handleToggle}
//         />
//     );
// };

function DiscountTable({ data }) {
    let location = useLocation();
    let params = new URLSearchParams(location.search);
    // Getting the query parameters with default values
  
    let id = params.get ('id') || 'defaultId';
    // console.log(id,"iddd")
    const navigate = useNavigate();
    const dispatch = useDispatch();
  
    const [rowsData, setRowsData] = useState([])
    const [apiRes,setApiRes] = useState()

   
    const handleStatusId = (ruleId,id) => {
         const updatedItems = [...apiRes];
         updatedItems[id] = { ...updatedItems[id], status: !updatedItems[id].status };
         setApiRes(updatedItems)
         dispatch(changeRuleStatus({
            id:ruleId,
            status:updatedItems[id].status 
         })).then(() =>{
            window.location.reload();
         })
        // setIsChecked(!isChecked)
    };
   
    const columns = [
        { key: 'RuleName', name: 'Rule Name' },
        { key: 'DiscountCategory', name: 'Discount Category' },
        { key: 'ValidFrom', name: 'Valid From' },
        { key: 'ValidTo', name: 'Valid To' },
        { key: 'status', name: 'status', formatter: stsFormatter },
        { key: 'buttons', name: 'Actions', formatter: ButtonsFormatter }

    ];

    const rows = [
        // { id: 1, title: 'Example',ValidFrom: '02-02-2022',ValidTo:'04-11-2023',status:false,buttons: <div className="editbutton_container"><button className = 'editbutton' onClick={() => handleClick(1)}><FaPencilAlt className="icon"/>  Edit</button></div> },
        // { id: 0, title: 'Demo',ValidFrom: '02-02-2022',ValidTo:'04-11-2023',status:true,buttons: <button onClick={() => handleClick(1)}>Button 1</button> }
    ];
    //   useEffect(() => {
    const ruleData = () => {
        apiRes?.length> 0 && apiRes?.map((rule,id) => {
            
            rows.push(
                {
                    RuleName: rule.rule_name,
                    DiscountCategory: rule.discount_type,
                    ValidFrom: moment(rule?.fromdate).format('YYYY-MM-DD'),
                    ValidTo: moment(rule?.to_date).format('YYYY-MM-DD'),
                    status:
                    <div>
                        {/* <label class="switch">
                        <input type="checkbox"  checked = {rule.status} onChange={() => handleStatusId(rule.id,id)}/>
                        <span class="slider round"></span>
                        </label> */}
                        <Form className="discount-table">
                          <Form.Check // prettier-ignore
                          type="switch"
                          id="custom-switch"
                          onChange={() => handleStatusId(rule.id,id)}
                          checked={rule.status}
                        />
                        </Form>
                        </div>
                    ,
        
                    buttons: <div className="editbutton_container"><button className='editbutton' onClick={() => handleClick(rule.id)}><FaPencilAlt className="icon" />  Edit</button></div>
                }
            )
        })
        setRowsData(rows)
    }

    // },[data])



    function handleClick(id) {
    //    dispatch(getIndividualRule(id)).then((res) => {
        navigate(`/editrules/${id}`)
    //    })

        // Add your button click logic here
    }
    function ButtonsFormatter({ row }) {
        return row.buttons;
    }
    function stsFormatter({ row }) {
        return row.status;
    }
useEffect(()=>{
    ruleData()
    dispatch(setUserId(id))
},[apiRes])
    useEffect(() => {
        setApiRes(data.data)
        ruleData()
    }, [data])
    return (
        <div>
      <ToastContainer />
            <DataGrid columns={columns} rows={rowsData} />
            <div>

    <br />


    
  </div>
  
        </div>
        
    );
}

export default DiscountTable;
