import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import StockTransfer from "./stockTransfer/StockTransfer";
import DamagedProducts from "./pages/saleReturn/DamagedProducts";
import ExchangeProduct from "./pages/saleReturn/exchangeProducts/ExchangeProduct";
import DiscountRules from "./pages/discountRules/DiscountRules";
import DiscountForm from "./pages/discountRules/DiscountForm";
import Profitability from "./pages/profitability/Profitability";
import Stockstatus from "./pages/stockstatus/Stockstatus";
import ProfitRanking from "./pages/profitability/ProfitRanking";
// import ArcTabs from "./pages/Incentive/Incetives";
import SemiCircleTabs from "./pages/Incentive/Incetives";
import NewIncentives from "./pages/Incentive/NewIncentives";
import TermsAndConditions from "./stockTransfer/TermsAndConditions";
import TrackingDetails from "./stockTransfer/TrackingDetails";

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<DiscountRules/>} />
        <Route path="editrules/:id" element={<DiscountForm />} />
        <Route path="stocktransfer" element={<StockTransfer />}/>
        <Route path="createrule" element={<DiscountForm/>} />
        <Route path="damagedproducts" element={<DamagedProducts/>} />
        <Route path="exchangeproducts" element={<ExchangeProduct/>} />
        <Route path="profitability" element={<Profitability/>} />
        <Route path="stockstatus" element={<Stockstatus/>} />
        <Route path="profitranking" element={<ProfitRanking/>}/>
        <Route path='incentives' element={<SemiCircleTabs/>}/>
        <Route path='incentivesTesting' element={<NewIncentives/>}/>
        <Route path='termsandconditons' element={<TermsAndConditions/>}/>
        <Route path='trackingdetails' element={<TrackingDetails/>}/>



        <Route path="*" element={<><h1>Page Not Found</h1></>} />
      </Routes>
    </Router>
  );
}

export default App;
