import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { URLS,STOCK_URLS } from "../../utils/strings";
import axios from "axios";
import { toast } from "react-toastify";


const baseurl = process.env.REACT_APP_BASE_URL;

export const getStockList = createAsyncThunk(
  "stockTransfer/getStockList",
  async (payload, thunkAPI) => {
    thunkAPI.dispatch(setLoader(true))
    try {
        let url = `${baseurl}${STOCK_URLS.STOCK_LIST}${payload?.params.id}&type=${payload.type}&days=${payload.days}`

        if(payload.model != undefined) {
            url += `&model=${payload?.params.model.value}`
        }
        if(payload.brand != undefined) {
            url += `&brand=${payload?.params?.brand.value}`
        }
        if(payload.store_name != undefined) {
            url += `&store_name=${payload?.params.store_name.label}`
        }
        if(payload.status != undefined) {
          url += `&status=${payload?.params?.status.value}`
      }
       const response = await axios.get(url)
    thunkAPI.dispatch(setLoader(false))
      return response?.data?.data;
    } catch (error) {
    thunkAPI.dispatch(setLoader(false))
    console.log(error);
    }
  }
);
export const getTrack = createAsyncThunk(
  "stockTransfer/getTrack",
  async (payload, thunkAPI) => {
    try {
        console.log(payload,"dddd")
        let url = `${baseurl}${STOCK_URLS.STOCK_TRACKING}${payload}`
       
        console.log(url,"url")
       const response = await axios.get(url)
      console.log(response?.data,"heeeeeee");
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const stockApprove = createAsyncThunk(
  "stockTransfer/stockApprove",
  async (payload, thunkAPI) => {
    try {
        console.log(payload,"dddd")
        let url = `${baseurl}${STOCK_URLS.STOCK_APPROVE_REJECT}`
       
        console.log(url,"url")
       const response = await axios.post(url,payload)
       if (response.data?.status) {
        toast.success(response.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }else{
        toast.error(response.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      console.log(response?.data,"heeeeeee");
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const stocktransfer = createSlice({
  name: "stocktransfer",
  initialState: {
    allTransferList: [],
    trackingDetails : {},
    approve_response : {},
    isLoader:false,
    stockdata:[]
  },
  reducers: {
    setNavbar: (state, action) => {
      state.activeLink = action.payload;
      return state;
    },
    setEditDetails: (state, action) => {
      state.editDetails = action.payload;
      return state;
    },
    setUserId: (state, action) => {
      state.user_id = action.payload;
      return state;
    },
    setLoader: (state, action) => {
      state.isLoader = action.payload;
      return state;
    },
    setActiveTab:(state, action) => {
      state.activeTab= action.payload;
      return state;
    },
    setStockData:(state,action)=>{
      state.stockdata=action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getStockList.fulfilled, (state, action) => {
      state.allTransferList = action.payload;
      return state;
    });
    builder.addCase(getTrack.fulfilled, (state, action) => {
      state.trackingDetails = action.payload;
      return state;
    });
    builder.addCase(stockApprove.fulfilled, (state, action) => {
      state.approve_response = action.payload;
      return state;
    });
   
   
  },
});

export const { setNavbar, setEditDetails, setUserId,setActiveTab,setLoader,setStockData} = stocktransfer.actions;

export default stocktransfer.reducer;
