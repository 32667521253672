import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { MultiSelect } from "react-multi-select-component";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { MdDeleteForever } from "react-icons/md";
import { IoMdAddCircle } from "react-icons/io";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  getStores,
  getBrands,
  getModals,
  validateRule,
  createNewRule,
  getIndividualRule,
} from "../../redux/reducers/rules";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import SearchWithMultiSelect from "../../components/MultiSelectDropdown";
import { BsGear } from "react-icons/bs";
import { Modal } from "react-bootstrap";

function DiscountForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const date = new Date();
  const formattedDate = moment(date).format("YYYY-MM-DD");

  const editDetails = useSelector((state) => state.rules.editDetails);
  const [fromDate, setFromDate] = useState(formattedDate);
  const [toDate, setToDate] = useState(formattedDate);
  const [selectedStore, setSelectedStore] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedModal, setSelectedModal] = useState([]);
  const [selectedBrandModelList, setSelectedBrandModelList] = useState(
    editDetails?.[0]?.model
  );
  const [selectedDiscountType, setSelectedDiscountType] = useState(
    editDetails?.[0]?.discount_type
  );
  const [selectedDiscountCategory, setSelectedDiscountCategory] = useState(
    editDetails?.[0]?.discount_category
  );
  const [ruleStatus, setRuleStatus] = useState(
    editDetails?.length > 0 ? editDetails?.[0]?.status : true
  );
  const storeList = useSelector((state) => state.rules.storeList);
  const modalList = useSelector((state) => state.rules.modalList);
  const brandList = useSelector((state) => state.rules.brandList);
  const user_id = useSelector((state) => state.rules.user_id);
  const [percentage, setPercentage] = useState("");
  const [rulesData, setRulesData] = useState([]);
  const [temporaryDis, setTemporraryDis] = useState();
  const [toDateError, setToDateError] = useState("");

  const [ruleName, setRuleName] = useState(editDetails?.[0]?.rule_name);
  const [rules, setRules] = useState(
    editDetails?.length > 0 ? editDetails?.[0]?.cash_discount_flat : []
  );

  const [clonnedData, setClonnedData] = useState([]);

  useEffect(() => {
    if (id) {
      dispatch(getIndividualRule(id)).then((res) => {
        setFromDate(res?.payload?.[0]?.fromdate);
        setToDate(res?.payload?.[0]?.to_date);
        setSelectedBrandModelList(res?.payload?.[0]?.model);
        setSelectedDiscountType(res?.payload?.[0]?.discount_type);
        setSelectedDiscountCategory(res?.payload?.[0]?.discount_category);
        setRuleStatus(
          res?.payload?.length > 0 ? res?.payload?.[0]?.status : true
        );
        setRuleName(res?.payload?.[0]?.rule_name);
        setRules(
          res?.payload?.length > 0 ? res?.payload?.[0]?.cash_discount_flat : []
        );
        setClonnedData(
          res?.payload?.length > 0 ? res?.payload?.[0]?.cash_discount_flat : []
        );
        const hasTemporaryDiscount = res?.payload?.[0]?.cash_discount_flat.some(
          (rule) => rule.temp_discount > 0
        );
        setIsTemporaryDiscount(hasTemporaryDiscount);
      });
    }
  }, [id]);

  const addRule = () => {
    const r = [...rules];

    r.push({
      Approval_Authority: "",
      minhandsetprice: "",
      maxhandsetprice: "",
      mindiscountprice: "",
      maxdiscountprice: "",
    });
    setRules(r);
  };

  function deleteRule(i) {
    const r = [...rules];
    r.splice(i, 1);
    setRules(r);
  }
  useEffect(() => {
    dispatch(getStores());
    dispatch(getBrands()).then((res) => {
      let brandsList = [];
      res.payload.forEach((eachItem) => {
        brandsList.push(eachItem.value);
      });
      dispatch(getModals({ brandName: brandsList })).then((modals) => {});
    });
  }, []);

  useEffect(() => {
    setRulesData(rules);
  }, [rules]);
  const handleRuleName = (e) => {
    setRuleName(e.target.value);
  };
  const validateRuleName = (e) => {
    setRuleName(e.target.value);
    let ruleValidationBody = {
      type: "create",
      rule_name: e.target.value,
    };
    if (e.target?.value?.length > 0) dispatch(validateRule(ruleValidationBody));
  };
  useEffect(() => {
    let modalIST = [];
    modalList.length > 0 &&
      modalList?.forEach((each) => {
        selectedBrand?.forEach((eachBrand) => {
          if (each.brand == eachBrand.value) {
            modalIST.push({
              label: each.label,
              value: each.value,
            });
          }
        });
      });
    setSelectedBrandModelList(modalIST);
  }, [selectedBrand, modalList]);

  const handleSelectChange = (event) => {
    setSelectedDiscountType(event.target.value);
  };
  const handleSelectCategory = (event) => {
    setSelectedDiscountCategory(event.target.value);
  };

  function createUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
  const createRule = () => {
    console.log(toDate, "to", fromDate, "from");
    // Check if all conditions are met
    if (
      ruleName &&
      fromDate &&
      toDate >= fromDate &&
      selectedStore.length > 0 &&
      selectedBrand.length > 0 &&
      selectedModal.length > 0 &&
      selectedDiscountCategory &&
      selectedDiscountType &&
      rules.length > 0 &&
      rules.every(
        (rule) =>
          rule.Approval_Authority &&
          rule.minhandsetprice >= 1 &&
          rule.maxhandsetprice >= 1 &&
          rule.mindiscountprice >= 1 &&
          rule.maxdiscountprice >= 1
      )
    ) {
      // If all conditions are met, proceed to create the rule
      let stores = [];
      let brands = [];
      let modals = [];
      selectedStore.forEach((item) => {
        stores.push(item.value);
      });
      selectedBrand.forEach((item) => {
        brands.push(item.value);
      });
      selectedModal.forEach((item) => {
        modals.push(item.value);
      });
      let payload = {
        store: stores,
        brand: brands,
        model: modals,
        discount_type: selectedDiscountType,
        discount_category: selectedDiscountCategory,
        rule_name: ruleName,
        fromdate: fromDate,
        to_date: toDate,
        cash_discount_percentage: [],
        created_at: new Date(),
        status: ruleStatus,
        user_id: user_id,
        cash_discount_flat: rules.map((rule) => ({
          ...rule,
          maxdiscountprice: parseInt(rule?.maxdiscountprice),
          expiry_time: rule?.expiry_time ? rule.expiry_time * 60000 : 0,
        })),
        id: createUUID(),
      };

      if (editDetails?.length > 0) {
        payload.id = editDetails?.[0]?.id;
      }

      dispatch(createNewRule(payload)).then((res) => {
        navigate(`/?id=${user_id}`);
      });
    } else {
      let errorMsg = "";

      if (!ruleName) {
        errorMsg += "Rule name is required.\n";
        return toast.error("Rule name is required");
      }
      if (!fromDate) {
        errorMsg += "From date is required.\n";
        return toast.error("From Date is required");
      }
      if (!toDate || toDate < fromDate) {
        errorMsg += "To date must be greater than or equal to from date.\n";
        return toast.error(
          "To date must be greater than or equal to from date"
        );
      }
      if (selectedStore.length === 0) {
        errorMsg += "Select at least one store.\n";
        return toast.error("Select at least one store");
      }
      if (selectedBrand.length === 0) {
        errorMsg += "Select at least one brand.\n";
        return toast.error("Select at least one brand");
      }
      if (selectedModal.length === 0) {
        errorMsg += "Select at least one model.\n";
        return toast.error("Select at least one model");
      }
      if (!selectedDiscountCategory) {
        errorMsg += "Select a discount category.\n";
        return toast.error("Select a discount category");
      }
      if (!selectedDiscountType) {
        errorMsg += "Select a discount type.\n";
        return toast.error("Select a discount type");
      }
      if (rules.length === 0) {
        errorMsg += "Add at least one rule.\n";
        return toast.error("Add at least one rule");
      }
      if (rules.some((rule) => !rule.Approval_Authority)) {
        errorMsg += "Select approval authority for all rules.\n";
        return toast.error("Select approval authority for all rules");
      }
      if (rules.some((rule) => rule.minhandsetprice < 1)) {
        errorMsg += "Enter min handset price for all rules.\n";
        return toast.error("Enter min handset price for all rules");
      }
      if (rules.some((rule) => rule.maxhandsetprice < 1)) {
        errorMsg += "Enter max handset price for all rules.\n";
        return toast.error("Enter min handset price for all rules");
      }

      if (rules.some((rule) => rule.maxdiscountprice < 1)) {
        errorMsg += "Enter max discount price for all rules.\n";
        return toast.error("Enter max discount price for all rules");
      }
      if (rules.some((rule) => rule.minhandsetprice !== NaN)) {
        errorMsg += "Enter min handset price for all rules.\n";
        return toast.error(" Please enter all Min & Max Prices ");
      }
    }
  };
  const handleSelectFromDate = (event) => {
    setFromDate(event.target.value);
    // Check if toDate is valid
    if (
      event.target.value &&
      toDate &&
      new Date(toDate) <= new Date(event.target.value)
    ) {
      setToDateError("To date must be greater  from date");
    } else {
      setToDateError("");
    }
  };
  const handleSelectToDate = (event) => {
    setToDate(event.target.value);
    // Check if toDate is valid
    if (
      event.target.value &&
      fromDate &&
      new Date(event.target.value) <= new Date(fromDate)
    ) {
      setToDateError("To date must be  than from date");
    } else {
      setToDateError("");
    }
  };

  useEffect(() => {
    let stores = [];
    let brands = [];
    let modals = [];
    editDetails?.[0]?.storeoutput?.forEach((item) => {
      stores.push({
        label: item.store_name,
        value: item._id,
      });
    });
    setSelectedStore(stores);
    editDetails?.[0]?.brand.forEach((item) => {
      brands.push({
        value: item,
        label: item,
      });
    });
    setSelectedBrand(brands);
    editDetails?.[0]?.model.forEach((item) => {
      modals.push({
        value: item,
        label: item,
      });
    });
    setSelectedModal(modals);
  }, [editDetails]);

  const handleCallbackStore = (childData) => {
    const filteredData = storeList.filter((item) =>
      childData.includes(item.value)
    );
    setSelectedStore(filteredData);
  };

  const handleCallbackBrand = (childData) => {
    const filteredData = brandList.filter((item) =>
      childData.includes(item.value)
    );
    setSelectedBrand(filteredData);
    const filteredModels = selectedModal.filter((modal) =>
      filteredData.some((brand) => brand.value === modal.brand)
    );
    setSelectedModal(filteredModels);
  };

  const handleCallbackModel = (childData) => {
    const filteredData = modalList.filter((item) =>
      childData.includes(item.value)
    );
    setSelectedModal(filteredData);
  };

  function calculateTotalFromPercentage() {
    if (!percentage) {
      return;
    }

    const fractionPercentage = parseFloat(percentage) / 100;

    const updatedRules = rules.map((item) => {
      if (item?.maxhandsetprice) {
        const per = fractionPercentage * parseInt(item.maxhandsetprice);
        const roundedDiscountPrice = per.toFixed(0);
        return {
          ...item,
          maxdiscountprice: roundedDiscountPrice.toString(),
        };
      } else {
        return item;
      }
    });
    setPercentage("");
    setRules(updatedRules);
  }

  const [showModal, setShowModal] = useState(false);

  function handleSettingsIconClick() {
    setShowModal(!showModal);
  }

  const [minHandsetPrice, setMinHandsetPrice] = useState("");
  const [maxHandsetPrice, setMaxHandsetPrice] = useState("");
  const [differenceAmount, setDifferenceAmount] = useState("");
  const [temp_discount, setTemp_discount] = useState(0);
  const [expiry_time, setExpiry_time] = useState(0);
  const [appendRulesError, setAppendRulesError] = useState("");

  const appendRules = () => {
    if (!minHandsetPrice || !maxHandsetPrice || !differenceAmount) {
      setAppendRulesError("Please fill in all fields to append rules.");
      return;
    }
    setAppendRulesError("");

    const newRules = [];
    let minhand = minHandsetPrice;

    for (let i = minHandsetPrice; i < maxHandsetPrice; i += differenceAmount) {
      if (i + differenceAmount < maxHandsetPrice) {
        const rule = {
          Approval_Authority: "storehead",
          minhandsetprice: i === minHandsetPrice ? i : i + 1,
          maxhandsetprice: i + differenceAmount,
          mindiscountprice: 1,
          maxdiscountprice: 0,
        };

        // Check if the rule already exists in the rules array
        const ruleExists = rules.some(
          (existingRule) =>
            existingRule.minhandsetprice === rule.minhandsetprice &&
            existingRule.maxhandsetprice === rule.maxhandsetprice
        );

        if (!ruleExists) {
          newRules.push(rule);
        }

        minhand = i + differenceAmount + 1;
      }
    }

    // Add the final rule
    const finalRule = {
      Approval_Authority: "storehead",
      minhandsetprice: minhand,
      maxhandsetprice: maxHandsetPrice,
      mindiscountprice: 1,
      maxdiscountprice: 0,
    };

    // Check if the final rule already exists in the rules array
    const finalRuleExists = rules.some(
      (existingRule) =>
        existingRule.minhandsetprice === finalRule.minhandsetprice &&
        existingRule.maxhandsetprice === finalRule.maxhandsetprice
    );

    if (!finalRuleExists) {
      newRules.push(finalRule);
    }

    setRules([...rules, ...newRules]);
    setShowModal(false);
  };

  const submitRules = () => {
    setMinHandsetPrice("");
    setMaxHandsetPrice("");
    setDifferenceAmount("");
    setExpiry_time(null);
  };

  const clearRules = () => {
    setRules([]);
  };

  const isEditing = editDetails !== null && editDetails !== undefined;

  const handleBack = () => {
    navigate(-1);
  };

  const [isTemporaryDiscount, setIsTemporaryDiscount] = useState(false);

  const toggleTemporaryDiscount = () => {
    setIsTemporaryDiscount(!isTemporaryDiscount);
  };

  const toggleStatus = () => {
    setRuleStatus(!ruleStatus);
  };
  return (
    <div className="containerStyles discount_form">
      <ToastContainer />
      <Button className="addrule_btn mt-5 mb-3" onClick={handleBack}>
        Back
      </Button>
      <h3 className="  mb-3">{id ? "Edit Rule" : "Create Rule"}</h3>
      <Container>
        <Row>
          <Col md={4}>
            <Form.Label>Rule Name:</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Enter Rule Name"
              value={ruleName}
              onChange={handleRuleName}
              onBlur={(e) => validateRuleName(e)}
            />
          </Col>
          <Col md={4}>
            <Form.Label> From Date:</Form.Label>
            <Form.Control
              required
              type="date"
              placeholder="Normal text"
              value={moment(fromDate).format("YYYY-MM-DD")}
              onChange={handleSelectFromDate}
            />
          </Col>
          <Col md={4}>
            <Form.Label>To Date:</Form.Label>
            <Form.Control
              required
              type="date"
              placeholder="Normal text"
              value={moment(toDate).format("YYYY-MM-DD")}
              onChange={handleSelectToDate}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={4}>
            <Form.Label>Select Store:</Form.Label>
            <SearchWithMultiSelect
              list={storeList}
              selectedStore={selectedStore}
              parentCallback={handleCallbackStore}
            />
          </Col>
          <Col md={4}>
            <Form.Label>Select Brand:</Form.Label>
            <SearchWithMultiSelect
              list={brandList}
              selectedStore={selectedBrand}
              parentCallback={handleCallbackBrand}
            />
          </Col>
          <Col md={4}>
            <div>
              <Form.Label>Select Model:</Form.Label>
              <SearchWithMultiSelect
                list={selectedBrandModelList}
                selectedStore={selectedModal}
                parentCallback={handleCallbackModel}
              />
            </div>
            {/* } */}
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={4}>
            <Form.Label>Discount Category:</Form.Label>
            <Form.Select
              required
              aria-label="Default select example"
              value={selectedDiscountCategory}
              onChange={handleSelectCategory}
            >
              <option>Open this select menu</option>
              <option value="cash_discount">Cash Discount</option>
            </Form.Select>
          </Col>
          <Col md={4}>
            <Form.Label>Discount Type:</Form.Label>
            <Form.Select
              required
              aria-label="Default select example"
              value={selectedDiscountType}
              onChange={handleSelectChange}
            >
              <option>Open this select menu</option>
              {/* <option value="1">Percentage</option> */}
              <option value="flat">Flat</option>
            </Form.Select>
          </Col>

          <Col md={4}>
            <div className="d-flex align-items-center justify-content-between h-100">
              <div>
                <Form.Label>Status:</Form.Label>
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  checked={ruleStatus}
                  onChange={toggleStatus}
                />
              </div>
              <div>
                <Form.Label>Temporary Discount:</Form.Label>
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  checked={isTemporaryDiscount}
                  onChange={toggleTemporaryDiscount}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col md={4}>
            <div className="d-flex align-items-center justify-content-between mt-3 w-100">
              <h4 className="mb-0 fs-5">Cash Discount Condition</h4>
              <BsGear size={24} onClick={handleSettingsIconClick} />
            </div>
          </Col>
        </Row>

        <Modal show={showModal} onHide={setShowModal}>
          <Modal.Header closeButton>
            <Modal.Title>Settings</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {appendRulesError && (
              <p className="text-danger">{appendRulesError}</p>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Minimum Handset Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Min. handset"
                value={minHandsetPrice}
                onChange={(e) => {
                  const value = +e.target.value;
                  if (value >= 1) {
                    setMinHandsetPrice(value);
                  } else {
                    setMinHandsetPrice("");
                  }
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Maximum Handset Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Max. handset"
                value={maxHandsetPrice}
                onChange={(e) => {
                  const value = +e.target.value;
                  if (value >= 1) {
                    setMaxHandsetPrice(value);
                  } else {
                    setMaxHandsetPrice("");
                  }
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Difference Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Difference Amount"
                value={differenceAmount}
                onChange={(e) => {
                  const value = +e.target.value;
                  if (value >= 1) {
                    setDifferenceAmount(value);
                  } else {
                    setDifferenceAmount("");
                  }
                }}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ marginRight: "auto" }}
              className="addrule_btn btn btn-primary"
              variant="primary"
              onClick={submitRules}
            >
              Clear Form
            </Button>

            <Button
              className="addrule_btn btn btn-primary"
              variant="secondary"
              color="red"
              onClick={clearRules}
            >
              Clear & Create
            </Button>

            <Button
              className="addrule_btn btn btn-primary"
              variant="secondary"
              onClick={appendRules}
            >
              Append
            </Button>
          </Modal.Footer>
        </Modal>

        <Row className="mb-3">
          <Col md={4}>
            <Form.Label>{`Handset Max price % -${">"} Discount Max Price`}</Form.Label>
            <div className="d-flex align-items-center w-100">
              <Form.Control
                type="number"
                placeholder="Enter value to calculate discount"
                value={percentage || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  setPercentage(value);
                }}
              />
              {percentage ? (
                <Button
                  className="submitButton"
                  onClick={calculateTotalFromPercentage}
                >
                  Apply
                </Button>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="table-responsive d-flex justify-content-center">
              <Table
                striped
                bordered
                hover
                responsive="sm"
                className="table_styles"
              >
                <thead>
                  <tr>
                    <th></th>
                    <th colSpan={2}>Handset Price </th>
                    <th colSpan={2}>Discount Price </th>
                    {isTemporaryDiscount && (
                      <>
                        <th colSpan={2}>Temporary Discount</th>
                      </>
                    )}
                    <th></th>
                  </tr>
                </thead>

                <thead>
                  <tr>
                    <th>Approval Authority</th>
                    <th>Min. Price </th>
                    <th>Max. Price </th>
                    <th>Min. Price </th>
                    <th> Max. Price </th>

                    {isTemporaryDiscount && (
                      <>
                        <th>Temp Discount</th>
                        <th>Expiry Time (Only in Minutes) </th>
                      </>
                    )}

                    <th>
                      <Button className="addrule_btn" onClick={addRule}>
                        {" "}
                        <IoMdAddCircle /> AddRule
                      </Button>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {rules?.length > 0 &&
                    rules.map((r, i) => {
                      return (
                        <>
                          <tr>
                            <td>
                              <Form.Select
                                aria-label="Select Approval"
                                value={rules?.[i]?.Approval_Authority}
                                onChange={(e) => {
                                  const updatedItems = [...rules];
                                  updatedItems[i] = {
                                    ...updatedItems[i],
                                    Approval_Authority: e.target.value,
                                  };
                                  setRules(updatedItems);
                                }}
                              >
                                <option>Open this select menu</option>
                                <option value="storehead">Store Head</option>
                                <option value="asm">ASM</option>
                                <option value="saleshead">Sales Head</option>
                              </Form.Select>
                            </td>
                            <td>
                              <Form.Control
                                required
                                type="number"
                                placeholder="Min. handset"
                                value={rules?.[i]?.minhandsetprice}
                                onChange={(e) => {
                                  const value = parseInt(e.target.value);
                                  if (value > 0 || e.target.value == "") {
                                    const updatedItems = [...rules];
                                    updatedItems[i] = {
                                      ...updatedItems[i],
                                      minhandsetprice: parseInt(e.target.value),
                                    };
                                    setRules(updatedItems);
                                  }
                                }}
                              />
                            </td>
                            <td>
                              <Form.Control
                                required
                                type="number"
                                placeholder="Max. handset"
                                value={rules?.[i].maxhandsetprice}
                                onChange={(e) => {
                                  const value = parseInt(e.target.value);
                                  if (value > 0 || e.target.value == "") {
                                    const updatedItems = [...rules];
                                    updatedItems[i] = {
                                      ...updatedItems[i],
                                      maxhandsetprice: parseInt(e.target.value),
                                    };
                                    setRules(updatedItems);
                                  }
                                }}
                              />
                            </td>
                            <td>
                              <Form.Control
                                required
                                type="number"
                                placeholder="Min. discountprice"
                                value={rules?.[i].mindiscountprice}
                                onChange={(e) => {
                                  const value = parseInt(e.target.value);
                                  if (value > 0 || e.target.value == "") {
                                    const updatedItems = [...rules];
                                    updatedItems[i] = {
                                      ...updatedItems[i],
                                      mindiscountprice: parseInt(
                                        e.target.value
                                      ),
                                    };
                                    setRules(updatedItems);
                                  }
                                }}
                              />
                            </td>
                            <td>
                              <Form.Control
                                required
                                type="number"
                                placeholder="Max. discountprice"
                                value={rules?.[i].maxdiscountprice}
                                onChange={(e) => {
                                  const value = parseInt(e.target.value);
                                  if (value > 0 || e.target.value == "") {
                                    const updatedItems = [...rules];
                                    updatedItems[i] = {
                                      ...updatedItems[i],
                                      maxdiscountprice: parseInt(
                                        e.target.value
                                      ),
                                    };
                                    setRules(updatedItems);
                                  }
                                }}
                              />
                            </td>

                            {isTemporaryDiscount && (
                              <>
                                {/* <th>Expiry Time (Only in Minutes)</th> */}
                                <td className="table_dis_number">
                                  <Form.Control
                                    type="number"
                                    placeholder="Temporary Discount"
                                    disabled={
                                      clonnedData?.[i]?.expiry_date_time
                                    }
                                    value={
                                      rules?.[i]?.temp_discount
                                        ? rules?.[i]?.temp_discount
                                        : ""
                                    }
                                    onChange={(e) => {
                                      const value = parseInt(e.target.value);
                                      if (value > 0 || e.target.value == "") {
                                        const updatedItems = [...rules];
                                        updatedItems[i] = {
                                          ...updatedItems[i],
                                          temp_discount: value,
                                        };
                                        setRules(updatedItems);
                                      }
                                    }}
                                  />
                                </td>
                                <td className="table_exp_number">
                                  <Form.Control
                                    type="number"
                                    placeholder="Expiry Time"
                                    disabled={
                                      clonnedData?.[i]?.expiry_date_time
                                    }
                                    value={
                                      rules?.[i]?.expiry_time
                                        ? rules?.[i]?.expiry_time
                                        : ""
                                    }
                                    onChange={(e) => {
                                      const value = parseInt(e.target.value);
                                      if (value > 0 || e.target.value == "") {
                                        const updatedItems = [...rules];
                                        updatedItems[i] = {
                                          ...updatedItems[i],
                                          expiry_time: parseInt(e.target.value),
                                        };
                                        setRules(updatedItems);
                                      }
                                    }}
                                  />
                                  {clonnedData?.[i]?.expiry_date_time && (
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                      }}
                                    >
                                      Expires In:{" "}
                                      {Math.max(
                                        Math.ceil(
                                          (new Date(
                                            clonnedData?.[i]?.expiry_date_time
                                          ).getTime() -
                                            new Date().getTime()) /
                                            (1000 * 60)
                                        ),
                                        0
                                      )}{" "}
                                      Min
                                    </span>
                                  )}

                                  {/* {clonnedData?.[i]?.expiry_date_time && <span style={{fontSize:"12px",fontWeight:"bold",textAlign:"center"}}> Expires In : {Math.ceil((new Date(clonnedData?.[i]?.expiry_date_time).getTime()-new Date().getTime())/(1000*60))} Min </span>} */}
                                </td>
                              </>
                            )}

                            <td>
                              <Button
                                variant="danger"
                                className="delete-btn"
                                onClick={() => {
                                  deleteRule(i);
                                }}
                              >
                                <MdDeleteForever />
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div>
              {/* {
    ruleName &&
    fromDate &&
    toDate >= fromDate &&
    selectedStore?.length > 0 &&
    selectedBrand?.length > 0 &&
    selectedModal?.length > 0 &&
    rules?.length > 0 ? ( */}
              <Button className="submitButton" onClick={createRule}>
                Submit
              </Button>
              {/* ) : (
      <div className="error-message ">Please fill in all the required fields.</div>
    )
  } */}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default DiscountForm;
