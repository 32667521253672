import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { URLS, STOCK_URLS } from "../../utils/strings";
import axios from "axios";
import { toast } from "react-toastify";
import { PROFITABILITY_URLS } from "../../utils/strings"
import moment from "moment";
import { create } from "lodash";


const baseurl = process.env.REACT_APP_BASE_URL;
const profitability_url = PROFITABILITY_URLS.BASE_URL
// const profitability_url = "http://192.168.0.170:8030"
export const getMTD = createAsyncThunk(
  "profitability/getMTD",
  async (payload, thunkAPI) => {
    try {
      let url = `${profitability_url}/api/profitability/profitTargets?type=${payload.type}`
      const response = await axios.post(url,
        payload.roles
      )
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getFTD = createAsyncThunk(
  "profitability/getFTD",
  async (payload, thunkAPI) => {
    try {
      let url = `${profitability_url}/api/profitability/profitTargets?type=${payload.type}`
      const response = await axios.post(url, payload.roles
      )
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getMonthData = createAsyncThunk(
  "profitability/getMonthData",
  async (payload, thunkAPI) => {
    try {
      let url = `${profitability_url}/api/profitability/profitTargets?from=${moment(new Date(payload.from)).format("YYYY-MM-DD")}&to=${moment(new Date(payload.to)).format("YYYY-MM-DD")}`
      const response = await axios.post(url, payload.roles)
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getProducts = createAsyncThunk(
  "profitability/getProducts",
  async (payload, thunkAPI) => {
    try {
      let url = `${profitability_url}/api/profitability/getProducts`
      const response = await axios.get(url)
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getProfitsheets = createAsyncThunk(
  "profitability/getProfitsheets",
  async (payload, thunkAPI) => {
    try {
      let url = `${profitability_url}/api/profitability/profitSheet`
      if (payload.model != undefined) {
        url += `?item=${payload.model.value}`
      }
      if (payload.brand != undefined) {
        url += `?brand=${payload.brand.value}`
      }
      if (payload.search != undefined && payload.search != "") {
        url += `?search=${payload.search}`
      }
      const response = await axios.get(url)
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getTransactions = createAsyncThunk(
  "profitability/getTransactions",
  async (payload, thunkAPI) => {
    try {
      let url = `${profitability_url}/api/profitability/transactionSheet?from=${moment(new Date(payload.from)).format("YYYY-MM-DD")}&to=${moment(new Date(payload.to)).format("YYYY-MM-DD")}`
      const response = await axios.post(url, payload.roles
      )
      let transactions = []
      let profit = 0
      let discount = 0
      let totalGst = 0
      let netProfit = 0
      response?.data?.data?.forEach(element => {
        profit = profit + element.gp
        discount = discount + element.total_discount
        if (element.item_code != "HC 180" && element.item_code != "HAPPI CARE") {
          totalGst = totalGst + element.gst
        }
        // if(element.item_code != "AVANTEE BAR AURA") {
        //   console.log("hellllllll",element.item_code)
        // totalGst = totalGst + element.gst
        // }
        //  totalGst = totalGst + element.gst
        netProfit = netProfit + element.net_profit
      });
      transactions = { "transactions": response?.data?.data, "profitDetails": response?.data?.metaData, "totalDiscount": discount, "totalGst": totalGst, "netProfit": netProfit }
      return transactions;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getBrandsandModels = createAsyncThunk(
  "profitability/getBrandsandModels",
  async (payload, thunkAPI) => {
    try {
      let url = `${profitability_url}/api/profitability/getBrandAndModel`
      const response = await axios.get(url)
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAccessories = createAsyncThunk(
  "profitability/getAccessories",
  async (payload, thunkAPI) => {
    try {
      let url = `${profitability_url}/api/profitability/getAccessories`
      const response = await axios.get(url)
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getHappicare = createAsyncThunk(
  "profitability/getHaapicare",
  async (payload, thunkAPI) => {
    try {
      let url = `${profitability_url}/api/profitability/getHappicare`
      const response = await axios.get(url)
      //  console.log("ress",response?.data)
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAllProfitsheets = createAsyncThunk(
  "profitability/getAllProfitsheets",
  async (payload, thunkAPI) => {
    try {
      let url = `${profitability_url}/api/profitability/profitSheet`

      const response = await axios.get(url)
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getSyncTime = createAsyncThunk(
  "profitability/LastSyncTime",
  async (payload, thunkAPI) => {
    try {
      let url = `${profitability_url}/api/profitability/lastSyncTime`

      const response = await axios.get(url);
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAllASM = createAsyncThunk(
  "profitability/getAllASM",
  async (payload, thunkAPI) => {
    try {
      let url = `${profitability_url}/api/profitability/getAsmBranches?phone=${payload}`

      const response = await axios.get(url)
      let list = [{ "label": "All", "value": "All" }];
      response?.data.data.forEach((element) => {
        list.push({
          label: element.branch_name,
          value: element.branch_code,
        });
      });
      return list;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAffordabilityData=createAsyncThunk('profitability/affordability',async(payload)=>{
  let response;
  try{
    if(payload.type=='ftd'||payload.type=='mtd'){
      response=await axios.post(`${profitability_url}/api/profitability/affordability?type=${payload.type}`,payload?.pay,{
        headers:{
          "Content-Type":"application/json"
        }
      })
    }
    else{
      response=await axios.post(`${profitability_url}/api/profitability/affordability?from=${moment(new Date(payload?.dates?.from)).format("YYYY-MM-DD")}&to=${moment(new Date(payload?.dates?.to)).format("YYYY-MM-DD")}`,payload?.pay,{
        headers:{
          "Content-Type":"application/json"
        }
      })
    }
    
    return response.data

  }catch(e){
  }
})

export const getMissedCustomersData=createAsyncThunk('profitability/affordability',async(payload)=>{
  let response;
  try{
    if(payload.type=='ftd'||payload.type=='mtd'){
      response=await axios.post(`${profitability_url}/api/profitability/missedCustomerData?type=${payload.type}`,payload?.pay,{
        headers:{
          "Content-Type":"application/json"
        }
      })
    }
    else{
      response=await axios.post(`${profitability_url}/api/profitability/missedCustomerData?from=${moment(new Date(payload?.dates?.from)).format("YYYY-MM-DD")}&to=${moment(new Date(payload?.dates?.to)).format("YYYY-MM-DD")}`,payload?.pay,{
        headers:{
          "Content-Type":"application/json"
        }
      })
    }
    
    return response.data

  }catch(e){
  }

})

export const getAllStores = createAsyncThunk(
  "profitability/getAllStores",
  async (payload, thunkAPI) => {
    try {
      let url;
      // let url = `${profitability_url}/api/profitability/getHierarchyMetaData?statehead=${payload?.stateHead?.value ? (payload?.stateHead?.value) : 0}&asm=${payload?.asm?.value ? (payload?.asm?.value) : 0}&phone=${payload.phone}&role=${payload.role}`
      if (payload.role == "store") {
        // response = await axios.get(url, { store_code: payload.store_code })
       url = `${profitability_url}/api/profitability/getHierarchyMetaData?statehead=${payload?.stateHead?.value ? (payload?.stateHead?.value) : 0}&asm=${payload?.asm?.value ? (payload?.asm?.value) : 0}&phone=${payload.phone}&role=${payload.role}&store_code=${payload.store_code}`
  console.log(url)
      } else {
       url = `${profitability_url}/api/profitability/getHierarchyMetaData?statehead=${payload?.stateHead?.value ? (payload?.stateHead?.value) : 0}&asm=${payload?.asm?.value ? (payload?.asm?.value) : 0}&phone=${payload.phone}&role=${payload.role}`
        
      }
      let response = await axios.get(url)
      let stateHeads = [];
      let asms = [];
      let stores = []
      response?.data.data?.asms?.forEach((element) => {
        asms.push({
          label: element.asm_name,
          value: element.id,
        });
      });
      response?.data.data?.stateHeads?.forEach((element) => {
        stateHeads.push({
          label: element.region,
          value: element.id,
        });
      });
      response?.data.data?.stores?.forEach((element) => {
        stores.push({
          label: element.store_name,
          value: element.id,
        });
      });
      let data = {
        stateHeads: stateHeads,
        asms: asms,
        stores: stores
      }
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const postCustomerDetails = createAsyncThunk(
  "profitability/postCustomerDetails",
  async (payload, thunkAPI) => {
    try {
      let url = `${profitability_url}/api/profitability/missedCustomer`
      const response = await axios.post(url, payload)
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  }
);


//ranking api's

export const getMTDRanking = createAsyncThunk(
  "profitability/getMTDRanking",
  async (payload, thunkAPI) => {
    try {
      let url = `${profitability_url}/api/profitability/netProfit?type=${payload.type}`
      const response = await axios.post(url,
        payload.roles
      )
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getPDaysLdays = createAsyncThunk(
  "profitability/LDaysPDays",
  async (payload, thunkAPI) => {
    let apiurl;
    if(payload.type=="mtd"){
      apiurl=`profitability/netProfitGainLossDays?type=${payload.type}`
    }
    if(payload.type=='custom'){
      apiurl=`profitability/netProfitGainLossDays?type=${payload.type}&from=${moment(new Date(payload.from)).format("YYYY-MM-DD")}&to=${moment(new Date(payload.to)).format("YYYY-MM-DD")}`
    }
    try {
      let url = `${profitability_url}/api/${apiurl}`
      const response = await axios.post(url,payload.payload)
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getFTDRanking = createAsyncThunk(
  "profitability/getFTDRanking",
  async (payload, thunkAPI) => {
    try {
      let url = `${profitability_url}/api/profitability/netProfit?type=${payload.type}`
      const response = await axios.post(url, payload.roles
      )
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getMonthDataRanking = createAsyncThunk(
  "profitability/getMonthDataRanking",
  async (payload, thunkAPI) => {
    try {
      let url = `${profitability_url}/api/profitability/netProfit?from=${moment(new Date(payload.from)).format("YYYY-MM-DD")}&to=${moment(new Date(payload.to)).format("YYYY-MM-DD")}`
      const response = await axios.post(url, payload.roles)
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const profitability = createSlice({
  name: "profitability",
  initialState: {
    activeTab: 'FTD',
    isLoader: false,
    mtdData: {},
    ftdData: {},
    monthData: {},
    activereportTable: 'ProfitSheets',
    productList: {},
    profitsheets: {},
    transactionsData: {},
    allbrands: {},
    allAccesseries: {},
    happicareList: {},
    allProfitsheets: {},
    asm: {},
    stores: {},
    mtdRankingData: {},
    ftdRankingData: {},
    monthRankingData: {},
    lDaysPDays:[]
  },
  reducers: {
    setNavbar: (state, action) => {
      state.activeLink = action.payload;
      return state;
    },
    setEditDetails: (state, action) => {
      state.editDetails = action.payload;
      return state;
    },
    setUserId: (state, action) => {
      state.user_id = action.payload;
      return state;
    },
    setLoader: (state, action) => {
      state.isLoader = action.payload;
      return state;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
      return state;
    },
    setModalTab: (state, action) => {
      state.activereportTable = action.payload;
      return state;
    },
    setSearchProfitsheets: (state, action) => {
      state.profitsheets = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMTD.fulfilled, (state, action) => {
      state.mtdData = action.payload;
      return state;
    });
    builder.addCase(getFTD.fulfilled, (state, action) => {
      state.ftdData = action.payload;
      return state;
    });
    builder.addCase(getMonthData.fulfilled, (state, action) => {
      state.monthData = action.payload;
      return state;
    });
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.productList = action.payload;
      return state;
    });
    builder.addCase(getProfitsheets.fulfilled, (state, action) => {
      state.profitsheets = action.payload;
      return state;
    });
    builder.addCase(getTransactions.fulfilled, (state, action) => {
      state.transactionsData = action.payload;
      return state;
    });
    builder.addCase(getBrandsandModels.fulfilled, (state, action) => {
      state.allbrands = action.payload;
      return state;
    });
    builder.addCase(getAccessories.fulfilled, (state, action) => {
      state.allAccesseries = action.payload;
      return state;
    });
    builder.addCase(getPDaysLdays.fulfilled, (state, action) => {
      state.lDaysPDays = action.payload;
      return state;
    });
    
    builder.addCase(getHappicare.fulfilled, (state, action) => {
      state.happicareList = action.payload;
      return state;
    });
    builder.addCase(getAllProfitsheets.fulfilled, (state, action) => {
      state.allProfitsheets = action.payload;
      return state;
    });
    builder.addCase(getAllASM.fulfilled, (state, action) => {
      state.asm = action.payload;
      return state;
    });
    builder.addCase(getAllStores.fulfilled, (state, action) => {
      state.stores = action.payload;
      return state;
    });
    builder.addCase(getMTDRanking.fulfilled, (state, action) => {
      state.mtdRankingData = action.payload;
      return state;
    });
    builder.addCase(getFTDRanking.fulfilled, (state, action) => {
      state.ftdRankingData = action.payload;
      return state;
    });
    builder.addCase(getMonthDataRanking.fulfilled, (state, action) => {
      state.monthRankingData = action.payload;
      return state;
    });
  },
});

export const { setNavbar, setEditDetails, setUserId, setActiveTab, setLoader, setModalTab, setSearchProfitsheets } = profitability.actions;

export default profitability.reducer;
