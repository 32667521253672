import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import moment from "moment";
import { GrStatusGood } from "react-icons/gr";
import { BsThreeDots } from "react-icons/bs";
import { FaQuestion } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { CiCirclePlus } from "react-icons/ci";
import { getTrack } from "../../redux/reducers/stocktranfer";
import { useLocation } from "react-router-dom";


export default function Stockstatus() {
    const dispatch = useDispatch();
    let location = useLocation();
    let params = new URLSearchParams(location.search);
    let id = params.get ('stockid') || 'defaultId';
    // let id = "93f0fbf0-e987-4092-9cb9-bf89474588ac"
    const trackingDetails = useSelector((state) => state.stocktransfer.trackingDetails);
    
    const statusBackgroundColor = (status) => {
        if (status == "PENDING") {
            return (
                {
                    background: "#efa742",
                    color: "#fff"
                }
            )
        }
        if (status == "OPTIONAL") {
            return (
                { background: "#2d4962", color: "#fff" }
            )
        }
        if (status == "REJECTED") {
            return { background: "#730405", color: "#fff" }
        }
        if (status == "APPROVED") {
            return (
                { background: "#15a05c", color: "#fff" }
            )
        }
        if (status == "CREATED") {
            return { background: "#3f89c7", color: "#fff" }
        }
    }
    const statusIcon = (status) => {
        if (status == "PENDING") {
            return <BsThreeDots />
        }
        if (status == "OPTIONAL") {
            return <FaQuestion />
        }
        if (status == "REJECTED") {
            return <IoMdClose />
        }
        if (status == "APPROVED") {
            return <GrStatusGood />
        }
        if (status == "CREATED") {
            return <CiCirclePlus />
        }
    }

    useEffect(() => {
        dispatch(getTrack(id))
    },[])
    return (
        <>
            <VerticalTimeline>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            // contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="STORE-1"
                            iconStyle={statusBackgroundColor(trackingDetails?.from_store?.status)}
                            icon={statusIcon(trackingDetails?.from_store?.status)}
                        >
                            <div className="ribbon"><span style={statusBackgroundColor(trackingDetails?.from_store?.status)}>{trackingDetails?.from_store?.status}</span></div>
                            <h3 className="vertical-timeline-element-title">{trackingDetails?.from_store?.name}</h3>
                            <h4 className="vertical-timeline-element-subtitle">{moment(trackingDetails?.from_store?.created_at).format("lll")}</h4>
                            {/* <p>Creative Direction, User Experience, Visual Design, Project Management, Team Leading</p> */}
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            date="STORE-2"
                            iconStyle={statusBackgroundColor(trackingDetails?.to_store?.status)}
                            icon={statusIcon(trackingDetails?.to_store?.status)}
                        >
                            <div className="ribbon"><span style={statusBackgroundColor(trackingDetails?.to_store?.status)}>{trackingDetails?.to_store?.status}</span></div>
                            <h3 className="vertical-timeline-element-title">{trackingDetails?.to_store?.name}</h3>
                            {(trackingDetails?.to_store?.status != "PENDING" && trackingDetails?.to_store?.status != "OPTIONAL") ? <h4 className="vertical-timeline-element-subtitle">{moment(trackingDetails?.to_store?.created_at).format("lll")}</h4> : <h4 className="vertical-timeline-element-subtitle">Waiting for Approval</h4>}
                            {/* <p>Creative Direction, User Experience, Visual Design, SEO, Online Marketing</p> */}
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            date="ASM-1"
                            iconStyle={statusBackgroundColor(trackingDetails?.asm_1?.status)}
                            icon={statusIcon(trackingDetails?.asm_1?.status)}
                        >
                            <div className="ribbon"><span style={statusBackgroundColor(trackingDetails?.asm_1?.status)}>{trackingDetails?.asm_1?.status}</span></div>
                            <h3 className="vertical-timeline-element-title">{trackingDetails?.asm_1?.name}</h3>
                            {(trackingDetails?.asm_1?.status != "PENDING" && trackingDetails?.asm_1?.status != "OPTIONAL") ? <h4 className="vertical-timeline-element-subtitle">{moment(trackingDetails?.asm_1?.created_at).format("lll")}</h4 > : <h4 className="vertical-timeline-element-subtitle">Waiting for Approval</h4>}
                            {/* <p> User Experience, Visual Design</p> */}
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            date="ASM-2"
                            iconStyle={statusBackgroundColor(trackingDetails?.asm_2?.status)}
                            icon={statusIcon(trackingDetails?.asm_2?.status)}
                        >
                            <div className="ribbon"><span style={statusBackgroundColor(trackingDetails?.asm_2?.status)}>{trackingDetails?.asm_2?.status}</span></div>
                            <h3 className="vertical-timeline-element-title">{trackingDetails?.asm_2?.name}</h3>
                            {(trackingDetails?.asm_2?.status != "PENDING" && trackingDetails?.asm_2?.status != "OPTIONAL") ? <h4 className="vertical-timeline-element-subtitle">{moment(trackingDetails?.asm_2?.created_at).format("lll")}</h4> : <h4 className="vertical-timeline-element-subtitle">Waiting for Approval</h4>}
                            {/* <p>User Experience, Visual Design</p> */}
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            date="STATE HEAD"
                            iconStyle={statusBackgroundColor(trackingDetails?.statehead?.status)}
                            icon={statusIcon(trackingDetails?.statehead?.status)}
                        >
                            <div className="ribbon"><span style={statusBackgroundColor(trackingDetails?.statehead?.status)}>{trackingDetails?.statehead?.status}</span></div>
                            <h3 className="vertical-timeline-element-title">{trackingDetails?.statehead?.name}</h3>
                            {(trackingDetails?.statehead?.status != "PENDING" && trackingDetails?.statehead?.status != "OPTIONAL") ? <h4 className="vertical-timeline-element-subtitle">{moment(trackingDetails?.statehead?.created_at).format("lll")}</h4> : <h4 className="vertical-timeline-element-subtitle">Waiting for Approval</h4>}
                            {/* <p>Strategy, Social Media</p> */}
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--education position-relative"
                            date="CORPORATE"
                            iconStyle={statusBackgroundColor(trackingDetails?.corporate?.status)}
                            icon={statusIcon(trackingDetails?.corporate?.status)}
                        >
                            <div className="ribbon"><span style={statusBackgroundColor(trackingDetails?.corporate?.status)}>{trackingDetails?.corporate?.status}</span></div>
                            <h3 className="vertical-timeline-element-title">{trackingDetails?.corporate?.name}</h3>
                            {(trackingDetails?.corporate?.status != "PENDING" && trackingDetails?.corporate?.status != "OPTIONAL") ? <h4 className="vertical-timeline-element-subtitle">{moment(trackingDetails?.corporate?.created_at).format("lll")}</h4> : <h4 className="vertical-timeline-element-subtitle">Waiting for Approval</h4>}
                            {/* <p>Creative Direction, User Experience, Visual Design</p> */}
                        </VerticalTimelineElement>
                    </VerticalTimeline>
        </>
    )
}
