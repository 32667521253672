import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker"
import axios from "axios";
import moment from "moment";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { MultiSelect } from "react-multi-select-component";
import Form from 'react-bootstrap/Form';
import Pagination from "react-bootstrap/Pagination";
import { Container, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "./ExchangeProduct.scss";
import Spinner from 'react-bootstrap/Spinner';
import Loader from "../../../components/loader/loader";
import { useSelector,useDispatch } from "react-redux";
import {setLoader} from "../../../redux/reducers/rules";

let baseurl = process.env.REACT_APP_BASE_URL
export default function ExchangeProduct() {
    const [data, setData] = useState([]);
    console.log("data",data)
    const [rowsData, setRowsData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({});
    console.log("modalData",modalData)
    const [activePage, setActivePage] = useState(1);
    const [selected, setSelected] = useState([]);
    const [customerMobile, setCustomerMobile] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [totalPages, setTotalPages] = useState(10);
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [pageNumber, setPageNumber] = useState(1)
    const [type, setType] = useState([]);
    const [approvebtnLoading, setApproveBtnLoading] = useState(false);
    const [rejectBtnLoading, setRejectBtnLoading] = useState(false);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.rules.isLoader);

    useEffect(() => {
        getExchangeData()
    }, [type, customerMobile, selected, endDate, startDate, recordsPerPage, pageNumber])

    const getExchangeData = async () => {
        let getData = await axios.get(`${baseurl}/api/salereturn/listOfExchangeproducts?fromdate=${startDate ? startDate : "all"}&todate=${endDate ? endDate : "all"}&type=${type?.[0]?.label ? type?.[0]?.label?.toLowerCase() : null}&customer_mobile=${customerMobile}&status=${selected?.[0]?.label ? selected?.[0]?.label : null}&pageNumber=${pageNumber}&pageSize=${10}`)
        setData(getData?.data?.data)
        setTotalPages(getData.data.metadata.total);
        dispatch(setLoader(false));
    }
    useEffect(() => {
        ruleData()
    }, [data])

    const handleActivePageChange = (page) => {

        if (page >= 1) {
            // setRecordsPerPage(page*recordsPerPage)
            setActivePage(page);
            setPageNumber(page)
        }
    };

    const formatCurrencyPrice=(price)=>{
        const formattedPrice = new Intl.NumberFormat('en-IN', {
          style: 'currency',
          currency: 'INR',
        }).format(price)
        return formattedPrice
      }
    //   formatCurrencyPrice((modalData?.replace_product?.item_gross_rate+ modalData?.replace_product?.item_tax_amt))

    const totalActivePages = Math.ceil(
        totalPages / 10
    );

    const rows = []

    const ruleData = () => {
        data?.length > 0 && data?.map((rule) => {

            rows.push(
                {
                    CustomerMobile: rule?.customer_mobile,
                    Type: rule?.type,
                    Reasons: rule?.reasons,
                    Status: rule?.status,
                    InvoiceNo: rule?.invoice_no,
                    InvoiceDate: rule?.invoice_date,
                    NewPrice: rule?.new_price,
                    StoreDetails: rule?.store_details?.store_code + ' ' + rule?.store_details?.store_name,
                    EmployeeDetails: rule?.employee_details.emp_id + ' ' + rule?.employee_details.name,
                    CreatedAt: moment(rule?.created_at).format('lll'),
                    Attachements: rule?.attachments,
                    Actions: <div className="editbutton_container"><button className='editbutton'>  View</button></div>

                }
            )
        })
        setRowsData(rows)
    }

    const statusOptions = [
        { label: "Exchange", value: "Exchange" },
        { label: "Approved", value: "Approved" },
        { label: "Rejected", value: "Rejected" },
        { label: "ReturnedToCustomer", value: "ReturnedToCustomer" },
        { label: "Received", value: "Received" },
        { label: "In-transit", value: "In-transit" },
    ];

    const typeOptions = [
        { label: "Same", value: "Same" },
        { label: "Different", value: "Different" },
    ];

    const onStatusChange = (data) => {
        if (data.length > 1) {
            let remove_item = data.pop()
            setSelected([remove_item])
        }
        else setSelected(data)
    }

    const onTypeChange = (data) => {
        if (data.length > 1) {
            let remove_item = data.pop()
            setType([remove_item])
        }
        else setType(data)
    }

    const approveReject = async (type) => {
        let payload = { emp_id: modalData?.admin_details?.emp_id, name: modalData?.admin_details?.name, mobile: modalData?.admin_details?.mobile, id: modalData.id, status: type }
        let res = await axios.put(`${baseurl}/api/salereturn/exchangeProductApproveByAdmin`, payload)
        if (res?.data?.status) { toast.success(res?.data?.message); getExchangeData(); setShowModal(false); setModalData({}) }
        setApproveBtnLoading(false)
        setRejectBtnLoading(false)
    }
    return (
        <>
         {loading ? (<Loader/>):(<>
            <section className="mt-2 pt-2 mt-sm-5 pt-sm-5 exchange_products">
                <Container>
                    <ToastContainer />
                    <Row>
                        <h1 className="m-0 mb-3">Exchange Products</h1>
                        <Col sm={6} md={6}>
                            <input type="text" placeholder="search with number" value={customerMobile} onChange={(e) => setCustomerMobile(e.target.value)} className="w-100" />
                        </Col>
                        <Col sm={6} className="mt-2 mt-md-0">
                            <MultiSelect
                                options={statusOptions}
                                hasSelectAll={false}
                                value={selected}
                                onChange={onStatusChange}
                                labelledBy="Select"
                            />
                        </Col>
                        <Col sm={6} className="mt-2">
                            <MultiSelect
                                options={typeOptions}
                                hasSelectAll={false}
                                value={type}
                                onChange={onTypeChange}
                                labelledBy="Select"
                            />
                        </Col>
                        <Col md={6} sm={12} className="mt-2">
                            <DatePicker className="form-control"
                                placeholderText="Start Date"
                                showIcon
                                selected={startDate ? new Date(startDate) : ""}
                                onChange={(date) => setStartDate(new Date(date).toISOString())}
                            />
                        </Col>
                        <Col md={6} sm={12} className="mt-2">
                            <DatePicker className="form-control"
                                placeholderText="End Date"
                                showIcon
                                selected={endDate ? new Date(endDate) : ""}
                                onChange={(date) => setEndDate(new Date(date).toISOString())}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {
                            data?.map((rule, id) => {
                                return (
                                    <Col sm={3}>
                                        <Card className="mt-3">
                                            <ListGroup variant="flush">
                                                <ListGroup.Item><label>Mobile:</label><p>{rule?.customer_mobile}</p></ListGroup.Item>
                                                <ListGroup.Item><label>Type:</label><p>{rule?.type}</p></ListGroup.Item>
                                                <ListGroup.Item><label>Status:</label><p>{rule?.status}</p></ListGroup.Item>
                                                <ListGroup.Item><label>Invoice Date:</label><p>{rule?.invoice_date}</p></ListGroup.Item>
                                                <ListGroup.Item><label>Invoice No:</label><p>{rule?.invoice_no}</p></ListGroup.Item>
                                                <ListGroup.Item><label>Store Details:</label><p>{rule?.store_details?.store_code + ' - ' + rule?.store_details?.store_name}</p></ListGroup.Item>
                                                <ListGroup.Item><label>Employee Details:</label><p>{rule?.employee_details.emp_id + ' -' + rule?.employee_details.name}</p></ListGroup.Item>
                                                <Button size="md" className="mx-5 my-2" onClick={() => { setModalData(rule); setShowModal(true) }}>View</Button>
                                            </ListGroup>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                    <Pagination className="justify-content-center mt-5 position-fixed bottom-0 start-0 bg-white p-3 mb-0 w-100">
                        {Array.from({ length: totalActivePages }).map((_, index) => {
                            const pageNumber = index + 1;
                            const isCurrentPage = pageNumber === activePage;

                            if (pageNumber === 1 || pageNumber === totalActivePages || Math.abs(pageNumber - activePage) <= 2) {
                                return (
                                    <Pagination.Item
                                        key={pageNumber}
                                        active={isCurrentPage}
                                        onClick={() => handleActivePageChange(pageNumber)}
                                        style={isCurrentPage ? { backgroundColor: "#FB9013", color: "#FB9013" } : {}}
                                    >
                                        {pageNumber}
                                    </Pagination.Item>
                                );
                            } else if (Math.abs(pageNumber - activePage) === 3) {
                                return <Pagination.Ellipsis key={pageNumber} />;
                            } else {
                                return null; 
                            }
                        })}
                    </Pagination>
                </Container>
            </section>
            <Modal show={showModal} onHide={() => { setShowModal(false); setModalData({}) }} className="exchange_modal" size="md">
                <Modal.Header closeButton>
                    <Modal.Title className="ps-2">Exchange Products</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-3">
                    <div className="mb-3">
                        <h3 className="mb-2">Old Products</h3>
                        <div><label>Item Code:</label><p>{modalData?.replace_product?.item_code}</p></div>
                        <div><label>Item Name:</label><p>{modalData?.replace_product?.item_name}</p></div>
                        <div><label>Item quantity:</label><p>{modalData?.replace_product?.item_quantity}</p></div>
                        <div><label>Item free qty:</label><p>{modalData?.replace_product?.item_free_qty}</p></div>
                        <div><label>Item gross rate:</label><p>{formatCurrencyPrice(modalData?.replace_product?.item_gross_rate.toFixed(2))}</p></div>
                        <div><label>Item tax amount:</label><p>{formatCurrencyPrice(modalData?.replace_product?.item_tax_amt.toFixed(2))}</p></div>
                        {/* <div><label>Item desc amount:</label><p>{modalData?.replace_product?.item_desc_amt}</p></div>
                        <div><label>Item cgst prec</label><p>{modalData?.replace_product?.item_cgst_prec}</p></div>
                        <div><label>Item sgst prec:</label><p>{modalData?.replace_product?.item_sgst_prec}</p></div>
                        <div><label>Item igst prec:</label><p>{modalData?.replace_product?.item_igst_prec}</p></div> */}
                        <div><label>IMEI NO:</label><p>{modalData?.replace_product?.imei_no}</p></div>
                        <div><label>Sales Person Code:</label><p>{modalData?.replace_product?.sales_person_code}</p></div>
                        <div><label>Sales Person Name:</label><p>{modalData?.replace_product?.sales_person_name}</p></div>
                        <div><label>Total:</label><p>{formatCurrencyPrice((modalData?.replace_product?.item_gross_rate+ modalData?.replace_product?.item_tax_amt))}</p></div>
                    </div>
                    <div className="mt-3 border-top">
                        <h3 className="mt-3 mb-2">New Products</h3>
                        <div> <label> Item Code:</label><p>{modalData?.new_product?.[0]?.item_code}</p></div>
                        <div><label>Item Name:</label><p>{modalData?.new_product?.[0]?.item_name}</p></div>
                        <div><label> Item Price:</label><p>{formatCurrencyPrice(modalData?.new_product?.[0]?.item_price)}</p></div>
                        <div> <label> Sub Quantity:</label><p>{modalData?.new_product?.[0]?.sub_quantity}</p></div>
                        <div><label> Sub Price:</label><p>{formatCurrencyPrice(modalData?.new_product?.[0]?.sub_price)}</p></div>
                        <div> <label> Availability:</label><p>{modalData?.new_product?.[0]?.availability_stock}</p></div>
                    </div>
                    {modalData.status == 'Exchange' && <div className="mt-3 d-flex justify-content-between">
                        <Button className="" variant="danger" onClick={() => { approveReject("Rejected"); setRejectBtnLoading(true) }} disabled={approvebtnLoading||rejectBtnLoading}>
                            {rejectBtnLoading?
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />:"Reject"}
                        </Button>
                        <Button className="btn btn-primary btn-md" onClick={() => { approveReject("Approved"); setApproveBtnLoading(true) }} disabled={approvebtnLoading||rejectBtnLoading}>
                           {approvebtnLoading?<Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />:"Approve"}
                        </Button>
                    </div>}

                    {modalData.type == "different" && <div className="mt-3">
                        <h6>Customer Loss Amount : {formatCurrencyPrice(modalData.customer_loss_amount.toFixed(2))}</h6>
                        <h6>Customer Extra Charges : {formatCurrencyPrice(modalData.extra_charges.toFixed(2))}</h6>
                    </div>}
                </Modal.Body>
            </Modal>
            </>)}
        </>
    )
}