import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Card from "react-bootstrap/Card";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import noData from "../../assets/no_data.png";
import Loader from "../../components/loader/loader";
import { setLoader } from "../../redux/reducers/rules";
import Spinner from 'react-bootstrap/Spinner';

let baseurl=process.env.REACT_APP_BASE_URL
export default function DamagedProducts() {
  const [data, setData] = useState([]);
  const [damagedPrdoucts, setDamagedProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const loading = useSelector((state) => state.rules.isLoader);
  const dispatch = useDispatch();
  const [receiveBtnLoading,setReceieveBtnLoading]=useState(false)
  
  useEffect(() => {
    getDamagedData();
    document.title = "Damaged products";
  }, []);

  const receivedProducts = async () => {
    setReceieveBtnLoading(true);
    let payload = {
      id: selectedData.id,
      emp_id: selectedData.employeeDetails?.emp_id,
      damaged_products: selectedData.damaged_products,
      mobile: selectedData.employeeDetails?.phone,
      name: selectedData.employeeDetails?.name,
    };
    let getData = await axios.post(
      `${baseurl}/api/packed/receivedDamagedProducts`,
      payload
    );
    if (getData.data.status) toast.success(getData.data.message);
    getDamagedData();
    setDamagedProducts([]);
    setSelectedData([]);
    setReceieveBtnLoading(false);
    setShowConfirmModal(false);
  };
  const getDamagedData = async () => {
    let getData = await axios.get(
      `${baseurl}/api/packed/damagedProducts`
    );
    if (getData.data.status) {
      setData(getData.data.data);
      dispatch(setLoader(false));
    }
    dispatch(setLoader(false));
  };
  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : data?.length > 0 ? (
        <div className="mt-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3 col-sm-12 mt-3">
                <div className="">
                  <ToastContainer />
                  {data.map((rule) => {
                    return (
                      <div className="damaged-products">
                        <Card className=" ">
                          <Card.Body>
                            <div className="d-flex align-items-center gap-1">
                              <label>Person Deatils:</label>
                              <p className="m-0">{rule?.person_details} </p>
                            </div>
                            <div className="d-flex align-items-center gap-1">
                              {" "}
                              <label>Delivery Through:</label>
                              <p className="m-0">{rule?.delivary_through}</p>
                            </div>
                            <div className="d-flex align-items-center gap-1">
                              {" "}
                              <label>Attachment:</label>
                              <p className="m-0"></p>
                            </div>
                            <div className="d-flex align-items-center gap-1">
                              {" "}
                              <label>Employee Details:</label>
                              <p className="m-0">
                                {rule?.employeeDetails?.emp_id +
                                  " " +
                                  rule?.employeeDetails?.name}
                              </p>
                            </div>
                            <div className="d-flex align-items-center gap-1">
                              {" "}
                              <label>Store Details:</label>
                              <p className="m-0">
                                {rule?.storeDetails?.store_code +
                                  " " +
                                  rule?.storeDetails?.store_name}
                              </p>
                            </div>
                            <div className="d-flex align-items-center gap-1">
                              {" "}
                              <label>Created At:</label>
                              <p className="m-0">
                                {moment(rule?.created_at).format("lll")}
                              </p>
                            </div>
                            <div className="d-flex align-items-center gap-1">
                              {" "}
                              <label>Actions:</label>
                              <Button
                                variant="primary"
                                onClick={() => {
                                  setShowModal(true);
                                  setDamagedProducts(rule?.damaged_products);
                                  setSelectedData(rule);
                                }}
                              >
                                View
                              </Button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    );
                  })}

                  <Modal
                    show={showModal}
                    onHide={() => {
                      setShowModal(false);
                    }}
                    centered
                    size="md"
                    className="damaged-products-modal"
                    scrollable={true}
                  >
                    <Modal.Header closeButton>
                      <h4 className="m-0">Damaged Products</h4>
                    </Modal.Header>
                    <Modal.Body>
                      {" "}
                      {damagedPrdoucts.map((item) => {
                        return (
                          <Card className="m-2 ps-4">
                            <Card.Body>
                              <div className="d-flex align-items-center gap-1">
                                <label>item:</label>
                                <p className="m-0">
                                  {item.replaced_product.item_name}
                                </p>
                              </div>
                              <div className="d-flex align-items-center gap-1">
                                {" "}
                                <label>item:</label>
                                <p className="m-0">{item.invoice_no}</p>
                              </div>
                              <div className="d-flex align-items-center gap-1">
                                {" "}
                                <label>item:</label>
                                <p className="m-0">{item.invoice_date}</p>
                              </div>
                            </Card.Body>
                          </Card>
                        );
                      })}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        size="md"
                        className="text-white bg-success border-0 fw-normal"
                        onClick={() => {
                          setShowConfirmModal(true);
                          setShowModal(false);
                        }}
                      >
                        Received
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal
                    show={showConfirmModal}
                    onHide={() => setShowConfirmModal}
                    centered
                    size="sm"
                  >
                    <Modal.Body>
                      <h3 className="m-0">Are you sure want to Confirm</h3>
                    </Modal.Body>
                    <Modal.Footer className="pt-0 border-0">
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setShowModal(false);
                          setDamagedProducts([]);
                          setSelectedData([]);
                          setShowConfirmModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button variant="primary" onClick={receivedProducts} disabled={receiveBtnLoading}>
                        {
                          receiveBtnLoading?<Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                      />:"Confirm"
                        }
                        
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center min-vh-100">
          <img src={noData} alt="no data found" title="no data found" />
        </div>
      )}
    </>
  );
}
