import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FaAngleUp, FaAngleDown } from "react-icons/fa6";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Loader from "../components/loader/loader";

const options = [
  { id: 1, label: 'Option 1' },
  { id: 2, label: 'Option 2' },
  { id: '2a', label: 'Option 2a' },
  { id: '2b', label: 'Option 2b' },
  { id: '2c', label: 'Option 2c' },
  { id: '2d', label: 'Option 2d' },
  { id: 3, label: 'Option 3' },
  { id: '3a', label: 'Option 3a' },
  { id: '3b', label: 'Option 3b' },
  { id: '3c', label: 'Option 3c' },
  { id: '3d', label: 'Option 3d' },
  // ...
];

function SearchWithMultiSelect({ list, selectedStore, parentCallback }) {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [showSelected,setShowSelected]=useState(false);

  const [selectedOptions, setSelectedOptions] = useState();
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const storeList = useSelector((state) => state.rules.storeList)
  const isLoader = useSelector((state) => state.rules.isLoader)
  const [filterOptions, setFilterOptions] = useState(list);
  const [content, setContent] = useState();

  const [storeDropdow, setStoreDropdow] = useState(false)
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);

    const filteredOptions = list?.filter((option) =>
      option?.label?.toString().toLowerCase().includes(event?.target?.value?.toString().toLowerCase())
    );
    setFilterOptions(filteredOptions);
  };
  const handleCheckboxChange = (optionId, isChecked) => {
    setIsSelectAllChecked(true);
    setSelectedOptions((prevSelectedOptions) => {
      let updatedOptions = prevSelectedOptions.includes(optionId)
        ? prevSelectedOptions.filter((id) => id !== optionId)
        : [...prevSelectedOptions, optionId];
      parentCallback(updatedOptions)
      return updatedOptions;
    });
  };


  const DeSelectAllChange = () => {
    if (selectedOptions?.length > 0 && searchTerm) {
      setIsSelectAllChecked(false);
      let val = filterOptions.map((option) => option.value).filter(item => selectedOptions?.some(val => val == item))
      let filterData = selectedOptions?.filter(item => !(val?.includes(item)))
      let temp = list?.map((option) => option.value);
      var filteredArray = temp.filter(e => {
        if (!val.find(item => item == e)) {
          return true
        } else {
          return false
        }
      })

      setSelectedOptions(filterData);
      parentCallback(filterData)
    }
    else if (!searchTerm) {
      setSelectedOptions([]);
      parentCallback([])
      setIsSelectAllChecked(false);
      const filteredOptions = list?.filter((option) =>
        option?.label?.toString().toLowerCase().includes(searchTerm?.toString().toLowerCase())
      );

      setFilterOptions(filteredOptions);
    }
  }
  const handleSelectAllChange = () => {
    setIsSelectAllChecked(true);
    let val = filterOptions?.map((option) => option.value)
    let removeDuplicates = [...new Set([...selectedOptions, ...val])]
    setSelectedOptions(removeDuplicates);
    parentCallback(removeDuplicates)
    const filteredOptions = list?.filter((option) =>
      option?.label?.toString().toLowerCase().includes(searchTerm?.toString().toLowerCase())
    );
    setFilterOptions(filteredOptions);

  };

  const filteredOptions = list?.filter((option) =>
    option?.label?.toString().toLowerCase().includes(searchTerm?.toString().toLowerCase())
  );
  //setFilterOptions(options);
  useEffect(() => {
    let values = []
    let inputContent
    inputContent = selectedStore?.map(item => item.label).join(', ');
    setContent(inputContent)
    selectedStore?.forEach(element => {
      values.push(element.value)
    });
    setSelectedOptions(values)
  }, [selectedStore])
  const storeDropdown = () => {
    setStoreDropdow(!storeDropdow)
  }
  useEffect(() => {
    setFilterOptions(list)
  }, [list])

  const showSelectedData=()=>{
    setShowSelected(!showSelected)

  }
  return (
    <div className='multiselectdropdown'>
      <div className='dropdown-container' aria-labelledby='Select' aria-readonly='true' onClick={storeDropdown}>
        <div className='dropdown-heading'>
          <div className='dropdown-heading-value'>
            {content ? <span>{content}</span> : <span className='grey'>Select</span>}
          </div>

          {storeDropdow ? <FaAngleDown /> : <FaAngleUp />}
        </div>
      </div>
      {storeDropdow && <div className='card p-0 mt-1'>
        <div className="">
          <input
            type="text"
            placeholder="Search options..."
            value={searchTerm}
            onChange={handleSearchChange}
            className='w-100 mb-2'
          />
        </div>

        {storeDropdow && <div className='d-flex px-2 gap-2 w-100'>
          <div className='w-100'>
            {filterOptions?.length > 0 && <button className='w-100 de-select-btn' onClick={DeSelectAllChange}>DeSelect</button>}
          </div>
          {/*   <label><input
          type="checkbox" 
          checked={selectedOptions.length>0}
          onChange={DeSelectAllChange}
        />
         De-Select All
      </label> */}
          <div className='w-100'>
            {!searchTerm && <button className='w-100 select-all' onClick={handleSelectAllChange}>Select All</button>
              //   <label>
              //   <input
              //     type="checkbox"
              //     checked={options?.length==selectedOptions?.length}
              //     onChange={handleSelectAllChange}
              //   />
              //   Select All
              // </label>
            }
            {
              searchTerm && filterOptions?.length > 0 && <button className='select-all w-100' onClick={handleSelectAllChange}>Select All</button>
              //    <label>
              //   <input
              //     type="checkbox"
              //     checked={filterOptions.every(item=>selectedOptions.some(val=>val==item.id))}
              //     onChange={handleSelectAllChange}
              //   />
              //   Select All
              // </label>
            }
          </div>
          {content&&<div className='w-100'>
            <button className='select-all w-100' onClick={showSelectedData}>Show All</button>
            </div>}
        </div>}
        {storeDropdow && <div className='col-12 scroller'>
          {isLoader ? <Loader /> : 
          <div>
          {filterOptions?.length > 0 ? filterOptions.map((option) => (
            // <div className='col-8 d-flex justify-content-center'>
            <div className='d-flex gap-2 align-items-center justify-content-start ps-3 py-1'>
              <input
                type="checkbox"
                id={option.value}
                checked={selectedOptions?.includes(option.value)}
                onChange={() => handleCheckboxChange(option.value, !selectedOptions.includes(option.value))}
              />
              <label for={option.value}>{option.label}
              </label>
            </div>
            //  </div>
          )) : "No Data Found"}
          
          </div>}
        </div>}
        {/* Your further processing/display of selected options goes here */}
      </div>}
      <Modal show={showSelected} onHide={showSelectedData} centered keyboard={true}   scrollable={true} size={"lg"}> 
        <Modal.Header closeButton>
          <Modal.Title>Selected Items</Modal.Title>
        </Modal.Header>
        <Modal.Body
      >
          <div>
            {
             content&& content?.split(',')?.map(item=><p>{item}</p>)
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
         
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default SearchWithMultiSelect;