import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FaFileSignature } from "react-icons/fa6";
// import DiscountTable from "../components/DiscountTable";
import DiscountTable from "../../components/DiscountTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveRules,
  getInActiveRules,
  setActiveTab,
  setEditDetails,
} from "../../redux/reducers/rules";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import Pagination from "react-bootstrap/Pagination";

export default function DiscountRules() {
  const navigate = useNavigate();
  const [key, setKey] = useState("Active Rules");
  // console.log("key",key)
  const [activePage, setActivePage] = useState(1);
  const [inactivePage, setInactivePage] = useState(1);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const activeRules = useSelector((state) => state.rules.activeRules);
  const activeTab = useSelector((state) => state.rules.activeTab);
  const inactiveRules = useSelector((state) => state.rules.inactiveRules);

  useEffect(() => {
    if (key === "Active Rules" && activePage > 0) {
      dispatch(
        getActiveRules({ pageNumber: activePage, pageSize: 10, search })
      );
    }

    if (key === "Inactive Rules" && inactivePage > 0) {
      dispatch(
        getInActiveRules({ pageNumber: inactivePage, pageSize: 10, search })
      );
    }
  }, [activePage, inactivePage, key, search]);

  const createRule = () => {
    dispatch(setEditDetails());
    dispatch(setActiveTab('Active Rules'))
    navigate("/createrule");
  };

  const handleActivePageChange = (page) => {
    if (page >= 1) {
      setActivePage(page);
    }
  };

  const handleInactivePageChange = (page) => {
    if (page >= 1) {
      setInactivePage(page);
    }
  };

  const onRuleSearch = (e) => {
    setSearch(e);
    setInactivePage(1);
    setActivePage(1);
  };

  const totalActivePages = Math.ceil(
    activeRules?.metadata?.total / activeRules?.metadata?.limit
  );
  const totalInactivePages = Math.ceil(
    inactiveRules?.metadata?.total / inactiveRules?.metadata?.limit
  );

  return (
    <div className="containerStyles">
      <ToastContainer />
      <Tabs
        id="controlled-tab-example"
        activeKey={activeTab}
        onSelect={(k) => {setKey(k); dispatch(setActiveTab(k))}}
        className="mb-3 mt-5 pt-5"
      >
        <Tab eventKey="Active Rules" title="Active Rules">
          <div className="discount_rules mb-3 d-flex align-items-center justify-content-between flex-wrap gap-3   ">
            <input
              className="align"
              type="text"
              placeholder="Search"
              onChange={(e) => onRuleSearch(e.target.value)}
            />
            <button className="button" onClick={createRule}>
              <FaFileSignature className="icon" />
              Create Discount Rules
            </button>
          </div>
          {activeRules && activeRules?.data?.length > 0 && (
            <>
              <DiscountTable data={activeRules} />

              <Pagination className="justify-content-center">
                {Array.from({ length: totalActivePages }, (_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === activePage}
                    onClick={() => handleActivePageChange(index + 1)}
                    style={
                      index + 1 === activePage
                        ? {
                            backgroundColor: "#FB9013",
                            color: "#FB9013",
                            backgroundColor: "#FB9013 !important",
                          }
                        : {}
                    }
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            </>
          )}
        </Tab>

        <Tab eventKey="Inactive Rules" title="Inactive Rules">
          <div className="discount_rules d-flex align-items-center justify-content-start my-3">
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => onRuleSearch(e.target.value)}
            />
          </div>
          {inactiveRules && inactiveRules?.data?.length > 0 && (
            <>
              <DiscountTable data={inactiveRules} />
              <Pagination className="justify-content-center">
                {Array.from({ length: totalInactivePages }, (_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === inactivePage}
                    onClick={() => handleInactivePageChange(index + 1)}
                    style={
                      index + 1 === inactivePage
                        ? {
                            backgroundColor: "#FB9013",
                            color: "#FB9013",
                            backgroundColor: "#FB9013 !important",
                          }
                        : {}
                    }
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
              {/* <Pagination className="justify-content-center">
                                <Pagination.Prev onClick={() => handleInactivePageChange(inactivePage - 1)} disabled={inactivePage === 1} />
                                <Pagination.Item className="activePage" style={{ backgroundColor: '#FB9013', color: '#FFFFFF' }} active>{inactivePage}</Pagination.Item>
                                <Pagination.Next onClick={() => handleInactivePageChange(inactivePage + 1)} disabled={!(inactiveRules?.metadata?.total / inactiveRules?.metadata?.limit > inactivePage)} />
                            </Pagination>
                            <div className="text-center mt-2">
      <span>Total Pages: {totalInactivePages}</span>
    </div> */}
            </>
          )}
        </Tab>
      </Tabs>
    </div>
  );
}
