import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { URLS } from "../../utils/strings";
import axios from "axios";
import { toast } from "react-toastify";

console.log("URLS",URLS)

const baseurl = process.env.REACT_APP_BASE_URL;
console.log("baseurl",baseurl)
// export const getActiveRules = createAsyncThunk('rules/getActiveRules', async (payload, thunkAPI) => {
//     try {
//         const response = await axios.get(`${URLS.baseUrl}${URLS.ACTIVE_RULES}`);
//         console.log(response?.data)
//         return response?.data
//     } catch (error) {
//         console.log(error)
//     }

//     //  return data

// })
// export const getInActiveRules = createAsyncThunk('rules/getinActiveRules', async (payload, thunkAPI) => {
//     try {
//         const response = await axios.get(`${URLS.baseUrl}${URLS.IN_ACTIVE_RULES}`);
//         console.log(response?.data,"iiii")
//         return response?.data
//     } catch (error) {
//         console.log(error)
//     }

//     //  return data

// })
export const getActiveRules = createAsyncThunk(
  "rules/getActiveRules",
  async ({ pageNumber, pageSize, search }, thunkAPI) => {
    console.log("URLS.baseUrl",URLS.baseUrl)
    try {
      const response = await axios.get(`${baseurl}${URLS.ACTIVE_RULES}`, {
        params: { pageNumber, pageSize, search },
      });
      console.log(response?.data);
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getInActiveRules = createAsyncThunk(
  "rules/getinActiveRules",
  async ({ pageNumber, pageSize, search }, thunkAPI) => {
    try {
      const response = await axios.get(
        `${baseurl}${URLS.IN_ACTIVE_RULES}`,
        {
          params: { pageNumber, pageSize, search },
        }
      );
      console.log(response?.data, "iiii");
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getStores = createAsyncThunk(
  "rules/getStores",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(`${baseurl}${URLS.STORE_LIST}`);
      console.log(response?.data, "iiii");
      let list = [];
      response?.data.data.forEach((element) => {
        list.push({
          label: element.store_name,
          value: element._id,
        });
      });
      console.log(list, "llll");
      return list;
    } catch (error) {
      console.log(error);
    }

    //  return data
  }
);

export const getModals = createAsyncThunk(
  "rules/getModals",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true))
      console.log("heyyy");
      const response = await axios.post(
        `${baseurl}${URLS.MODAL_LIST}`,
        payload
      );
      console.log(response?.data, "m");
      let list = [];
      console.log(response.data,"ddddd")
      response?.data.message.forEach((element) => {
        list.push({
          label: element.ITEM_NAME,
          value: element.ITEM_NAME,
          brand: element.BRAND_NAME,
        });
      });
      thunkAPI.dispatch(setLoader(false))
      console.log(list,"lllllllllllllllllll")
      return list;
    } catch (error) {
      console.log(error, "catttt");
      thunkAPI.dispatch(setLoader(false))
    }

    //  return data
  }
);
export const getBrands = createAsyncThunk(
  "rules/getBrands",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(`${baseurl}${URLS.BRAND_LIST}`);
      let list = [];
      console.log(response?.data.data);
      response?.data.data.forEach((element) => {
        list.push({
          label: element,
          value: element,
        });
      });
      console.log(list, "llll");
      return list;
    } catch (error) {
      console.log(error);
    }

    //  return data
  }
);

export const validateRule = createAsyncThunk(
  "rules/validateRule",
  async (payload, thunkAPI) => {
    try {
      console.log("heyyyyy");
      const response = await axios.post(
        `${baseurl}${URLS.RULE_NAME_VALIDATION}`,
        payload
      );
      console.log(response?.data, "ggggggg");
      if (response.data.status == false) {
        toast.warn(response?.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          // transition: Bounce,
        });
      }

      return response.data;
    } catch (error) {
      console.log(error);
    }

    //  return data
  }
);

export const createNewRule = createAsyncThunk(
  "rules/createNewRule",
  async (payload, thunkAPI) => {
    try {
      console.log("heyyyyy");
      const response = await axios.post(
        `${baseurl}${URLS.RULES_CREATE}`,
        payload
      );
      console.log(response?.data, "ggggggg");
      if (response.data?.status == true) {
        toast.success("Rule Created Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      return response.data;
    } catch (error) {
      console.log(error);
    }

    //  return data
  }
);

export const getIndividualRule = createAsyncThunk(
  "rules/getIndividualRule",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(
        `${baseurl}${URLS.INDIVIDUAL_RULE_DETAILS}${payload}`
      );
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }

    //  return data
  }
);

export const changeRuleStatus = createAsyncThunk(
  "rules/changeRuleStatus",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.put(
        `${baseurl}${URLS.RULE_STATUS}`,
        payload
      );
      if (response.data?.status == true) {
        toast.success("Status Changed Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      return response.data;
    } catch (error) {
      console.log(error);
    }

    //  return data
  }
);
export const rules = createSlice({
  name: "rules",
  initialState: {
    activeRules: {},
    inactiveRules: {},
    storeList: [],
    modalList: [],
    brandList: [],
    ruleValidateResponse: {},
    createRuleResponse: {},
    editDetails: [],
    user_id: {},
    isLoader: true,
    activeTab:'Active Rules'
  },
  reducers: {
    setNavbar: (state, action) => {
      state.activeLink = action.payload;
      return state;
    },
    setEditDetails: (state, action) => {
      state.editDetails = action.payload;
      return state;
    },
    setUserId: (state, action) => {
      state.user_id = action.payload;
      return state;
    },
    setLoader: (state, action) => {
      state.isLoader = action.payload;
      return state;
    },
    setActiveTab:(state, action) => {
      state.activeTab= action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getActiveRules.fulfilled, (state, action) => {
      state.activeRules = action.payload;
      return state;
    });
    builder.addCase(getInActiveRules.fulfilled, (state, action) => {
      state.inactiveRules = action.payload;
      return state;
    });
    builder.addCase(getStores.fulfilled, (state, action) => {
      state.storeList = action.payload;
      return state;
    });
    builder.addCase(getModals.fulfilled, (state, action) => {
      state.modalList = action.payload;
      return state;
    });
    builder.addCase(getBrands.fulfilled, (state, action) => {
      state.brandList = action.payload;
      return state;
    });
    builder.addCase(validateRule.fulfilled, (state, action) => {
      state.ruleValidateResponse = action.payload;
      return state;
    });
    builder.addCase(createNewRule.fulfilled, (state, action) => {
      state.createRuleResponse = action.payload;
      return state;
    });
    builder.addCase(getIndividualRule.fulfilled, (state, action) => {
      state.editDetails = action.payload;
      return state;
    });
  },
});

export const { setNavbar, setEditDetails, setUserId,setActiveTab,setLoader } = rules.actions;

export default rules.reducer;
