import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getIncentivesData=createAsyncThunk('incentives/getMobile',async(payload)=>{
    let response;
    try{
    if(payload.type=="consolidated"&&payload?.tab=='FTD'){
        response=await axios.get(`https://mis-api.happimobiles.com/api/incentives/${payload.type}?emp_code=${payload.emp_id}&type=${payload.tab}`)
    }
    else if(payload.type=="consolidated"&&payload?.tab=='MTD'){
        response=await axios.get(`https://mis-api.happimobiles.com/api/incentives/${payload.type}?emp_code=${payload.emp_id}&type=${payload.tab}&month=${payload.month}&year=${payload.year}`)
    }
    else{
        response=await axios.get(`https://mis-api.happimobiles.com/api/incentives/getIncentive?target_type=${payload?.type}&emp_code=${payload.emp_id}&type=${payload.tab}&month=${payload.month}&year=${payload.year}`)
    }
    let result=response.data
    return result?.data
    }
    catch(e){
  console.log('errr',e.message)
    }
})


export const getHierarchyData=createAsyncThunk('incentives/hirerachy',async(payload)=>{
    let response;
    try{
    response=await axios.get(`https://mis-api.happimobiles.com/api/incentives/getHierarchyMetaData?emp_code=${payload}`)
    let result=response.data
    return result?.data
    }
    catch(e){
  console.log('errr',e.message)
    }
})

export const incentiveSlice=createSlice({
    name:"incentive",
    initialState:{
        activeTab:0,
        incentivesData:[],
        hirerachyData:{}
    },
    extraReducers:(builder)=>{
        builder.addCase(getIncentivesData.fulfilled,(state,action)=>{
            state.incentivesData=action.payload
        })
        builder.addCase(getHierarchyData.fulfilled,(state,action)=>{
            state.hirerachyData=action.payload
        })
     
    },
    reducers:{
        setIncentiveActiveTab:(state,action)=>{
           state.activeTab=action.payload
        }
    }
})

export const {setIncentiveActiveTab}=incentiveSlice.actions
export default incentiveSlice.reducer