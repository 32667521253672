import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./ProfitRanking.scss";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Container, Row, Col } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { FaArrowUp, FaArrowDown, FaArrowLeft } from "react-icons/fa";

const ProfitRanking = () => {
  
  const location = useLocation();
  const props = location.state;
  
  const [rankingTab, setRankingTab] = useState("PROFIT");
  const [gainData, setGainData] = useState(props?.data?.gainData?.gainStores);
  const [lostData, setLossDaata] = useState(props?.data?.lossData?.lossStores);

  const filterLostData = (type) => {
    let filteredData;
    if (type == "asscending" && props?.data?.lossData?.lossStores.length > 0) {
      filteredData = [...props?.data?.lossData?.lossStores].sort(
        (a, b) => a["net_profit"] - b["net_profit"]
      );
    }
    if (type == "descending" && props?.data?.lossData?.lossStores.length > 0) {
      filteredData = [...props?.data?.lossData?.lossStores].sort(
        (a, b) => b["net_profit"] - a["net_profit"]
      );
    }
    setLossDaata(filteredData);
  };
  const filterGainData = (type) => {
    let filteredData;
    if (type == "asscending" && props?.data?.gainData?.gainStores.length > 0) {
      filteredData = [...props?.data?.gainData?.gainStores].sort(
        (a, b) => a["net_profit"] - b["net_profit"]
      );
    }
    if (type == "descending" && props?.data?.gainData?.gainStores.length > 0) {
      filteredData = [...props?.data?.gainData?.gainStores].sort(
        (a, b) => b["net_profit"] - a["net_profit"]
      );
    }
    setGainData(filteredData);
  };

  return (
    <div className="profitRanking">
      <Container>
        <Row>
          <Col>
            <Button
              size="lg"
              className="back-btn px-3 py-2 my-3 d-flex align-items-center justify-content-center"
              onClick={() => window.history.back()}
            >
              <FaArrowLeft className="fs-6" />
            </Button>
            <h2>Profit Ranking</h2>
            <div className="position-relative">
              <Tabs
                id="controlled-tab-example"
                activeKey={rankingTab}
                onSelect={(k) => setRankingTab(k)}
                className="mb-3"
              >
                <Tab eventKey="PROFIT" title="PROFIT">
                  <Container>
                    <Row>
                      <Col xs={12}>
                        <p>
                          Count of Profit Stores :{" "}
                          {props?.data?.gainData?.totalGainNetProfitCount}
                        </p>
                        <p>
                          Sum of Profit Stores :{" "}
                          {props?.data?.gainData?.totalGainNetProfit}
                        </p>
                        <Table responsive className="w-100">
                          <thead>
                            <tr className="border-1">
                              <th>#</th>
                              <th>Profit Store</th>
                              <th>
                                Value
                                <FaArrowUp
                                  size={15}
                                  onClick={() => filterGainData("descending")}
                                  className="ms-2"
                                />
                                <FaArrowDown
                                  size={15}
                                  onClick={() => filterGainData("asscending")}
                                />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(gainData) &&
                              gainData.length > 0 &&
                              gainData.map((item, index) => (
                                <tr className="border-1">
                                  <td>{index + 1}</td>
                                  <td key={index}>{item.branch_name}</td>
                                  <td key={index}>{item.net_profit}</td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Container>
                </Tab>

                <Tab eventKey="LOSS" title="LOSS" className="position-relative">
                  <Container>
                    <Row>
                      <Col xs={12}>
                        <p>
                          Count of Loss Stores :{" "}
                          {props?.data?.lossData?.totalLossNetProfitCount}
                        </p>
                        <p>
                          Sum of Loss Stores :{" "}
                          {props?.data?.lossData?.totalLossNetProfit}
                        </p>
                        <Table responsive className="w-100">
                          <thead>
                            <tr className="border-1">
                              <th>#</th>
                              <th>Loss Store</th>
                              <th>
                                Value
                                <FaArrowUp
                                  size={15}
                                  onClick={() => filterLostData("asscending")}
                                  className="ms-2"
                                />
                                <FaArrowDown
                                  size={15}
                                  onClick={() => filterLostData("descending")}
                                />
                              </th>
                              {/* <th><FaArrowUp size={30}/></th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(lostData) &&
                              lostData.length > 0 &&
                              lostData.map((item, index) => (
                                <tr className="border-1">
                                  <td>{index + 1}</td>
                                  <td key={index}>{item.branch_name}</td>
                                  <td key={index}>{item.net_profit}</td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Container>
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProfitRanking;
