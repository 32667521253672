// src/NewIncentives.js
import React from 'react';
import './NewIncentive.scss';

const NewIncentives = () => {
  return (
    <div className="multi-graph margin">
      <div className="graph" data-name="jQuery" style={{ '--percentage': 70, '--fill': '#0669AD' }} onClick={()=>console.log("hELLO")}>
        Tab 1
      </div>
      <div className="graph" data-name="React" style={{ '--percentage': 60, '--fill': '#FF3D00' }}>
        Tab 2
      </div>
      <div className="graph" data-name="React" style={{ '--percentage': 50, '--fill': '#e3d4d3' }}>
        Tab 3
      </div>
      <div className="graph" data-name="React" style={{ '--percentage': 40, '--fill': '#e03428' }}>
        Tab 4
      </div>
      <div className="graph" data-name="React" style={{ '--percentage': 30, '--fill': '#301513' }}>
        Tab 5
      </div>
      <div className="graph" data-name="React" style={{ '--percentage': 20, '--fill': '#e82a1c' }}>
        Tab 6
      </div>
      <div className="graph" data-name="React" style={{ '--percentage': 10, '--fill': '#2a1ce8' }}>
        Tab 7
      </div>
    </div>
  );
};

export default NewIncentives;
