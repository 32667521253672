import React from "react";
import "./loader.scss";

const Loader = () => {
  return (
    <div className="loader">
      <div class="loading">
        <div class="fingerprint-spinner">
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
        </div>
        <h1>loading...</h1>
      </div>
    </div>
  );
};

export default Loader;
