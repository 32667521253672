export const URLS = {
  ACTIVE_RULES: "/api/managerrules/getRulesList?status=true",
  IN_ACTIVE_RULES: "/api/managerrules/getRulesList?status=false",
  RULES_CREATE: "/api/managerrules/createRules",
  STORE_LIST: "/api/discount/getAllStores",
  BRAND_LIST: "/api/discount/getBrandNames",
  MODAL_LIST: "/api/discount/getMultiItemNames",
  RULE_NAME_VALIDATION: "/api/managerrules/rulenameValidation",
  INDIVIDUAL_RULE_DETAILS: "/api/managerrules/getmanagerRulesById?id=",
  RULE_STATUS: "/api/managerrules/updateRuleStatus",
};

export const STOCK_URLS = {
  STOCK_LIST : "/api/stocktransfer/getApprovalStockList?id=",
  STOCK_TRACKING : "/api/stocktransfer/stockTracking?stockid=",
  STOCK_APPROVE_REJECT : "/api/stocktransfer/approveLevelStocks"
}

export const PROFITABILITY_URLS = {
  BASE_URL : "https://mis-api.happimobiles.com"
}
//94d66e31-9f5b-49f9-929f-df15e1c5717c