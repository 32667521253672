import React from 'react'
import "./IncentivesLoader.scss"

const IncentivesLoader = () => {
  return (
    <>
    <div className='body incentiveloader'>
    <div className="content">
    <div className="cuboid">
      <div className="side"></div>
      <div className="side"></div>
      <div className="side"></div>
      <div className="side"></div>
      <div className="side"></div>
      <div className="side"></div>
    </div>
    <div className="cuboid">
      <div className="side"></div>
      <div className="side"></div>
      <div className="side"></div>
      <div className="side"></div>
      <div className="side"></div>
      <div className="side"></div>
    </div>
    <div className="cuboid">
      <div className="side"></div>
      <div className="side"></div>
      <div className="side"></div>
      <div className="side"></div>
      <div className="side"></div>
      <div className="side"></div>
    </div>
  </div>
  <h1>Loading...</h1>
  </div>
  </>
  )
}

export default IncentivesLoader
