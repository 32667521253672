import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Container, Row, Col, Card } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import { stockApprove } from "../redux/reducers/stocktranfer";
import "./TermsAndConditions.scss";
import { FaArrowLeft } from "react-icons/fa6";

const TermsAndConditions = () => {
  const modalData = useSelector((state) => state.stocktransfer.stockdata.data);
  const [checked, setChecked] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const approver = useSelector(
    (state) => state.stocktransfer.stockdata.approver
  );
  const store = useSelector((state) => state.stocktransfer.stockdata.store);
  const dispatch = useDispatch();
  const empid = useSelector((state) => state.stocktransfer.stockdata.empid);

  const backbtn = () => {
    window.history.back();
  };

  const termsConditionCheck = () => {
    if (checked) {
      setShowModal(true);
      // scrollToTop()
      setShowTermsModal(false);
      setChecked(false);
    } else {
      toast.error("Please Agree Terms and Conditions");
    }
  };
  const approveAndReject = (id, status) => {
    dispatch(
      stockApprove({
        action: status,
        approver: approver,
        empid: empid,
        stockid: id,
        store: store,
      })
    ).then((res) => {
      setShowModal(false);
      backbtn()
    });
  };
  return (
    <div className="term_page">
      <Container>
        <Row className="justify-content-center pt-2">
          <Col sm={3} className="h-ctrl">
            <FaArrowLeft onClick={backbtn} className="fs-5 my-2 ps-1" />
            <Card className="p-3">
              <h6>Terms & Conditions</h6>
              <div className="mb-3">
                <ul>
                  <li>
                    Transport of stock from store to store in the same district
                    is prohibited without proper authorization. Approval from
                    the respective ASM of the store is required and if any
                    damage occurred or if the product goes missing then during
                    the transit then ASM’s of such stores will be held
                    responsible.
                  </li>
                  <li>
                    Transport of stock between stores located in different
                    districts is prohibited without proper authorization.
                    Approval from the respective ASM of the store is required
                    and if any damage occurred or if the product goes missing
                    then during the transit then ASM’s of such stores will be
                    held responsible.
                  </li>
                </ul>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <Form>
                  <Form.Check
                    type={"checkbox"}
                    id={`default-checkbox`}
                    label={`I agree`}
                    checked={checked}
                    onChange={() => setChecked((prev) => !prev)}
                  />
                </Form>
                <button
                  onClick={termsConditionCheck}
                  disabled={!checked}
                  className="btn btn-primary"
                >
                  Proceed
                </button>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        className="transferdetails_modal"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="ps-2">Transfer Details</Modal.Title>
          {(modalData?.need_to_approve_by == "asm" &&
            modalData?.need_to_approve_by == approver &&
            !store.includes(modalData?.approved_asm)) ||
          (modalData?.need_to_approve_by != "asm" &&
            modalData?.need_to_approve_by == approver) ? (
            <>
              {" "}
              <Button
                className="mx-2 ms-auto"
                size="sm"
                variant="danger"
                onClick={() => approveAndReject(modalData?.id, "reject")}
              >
                Reject
              </Button>
              <Button
                className="btn btn-primary btn-md"
                size="sm"
                onClick={() => approveAndReject(modalData?.id, "approve")}
              >
                Approve
              </Button>
            </>
          ) : null}
        </Modal.Header>
        <Modal.Body className="p-3">
            <table>
              <tbody>
                <tr>
                  <td>Brand</td>
                  <td>:</td>
                  <td>{modalData?.brand}</td>
                </tr>
                <tr>
                  <td>Model</td>
                  <td>:</td>
                  <td>{modalData?.model}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>:</td>
                  <td>{modalData?.status}</td>
                </tr>
                <tr>
                  <td>From StoreName</td>
                  <td>:</td>
                  <td>{modalData?.from_store_name}</td>
                </tr>
                <tr>
                  <td>To StoreName</td>
                  <td>:</td>
                  <td>{modalData?.to_store_name}</td>
                </tr>
                <tr>
                  <td>Quantity</td>
                  <td>:</td>
                  <td>{modalData?.quantity}</td>
                </tr>
                <tr>
                  <td>Need to Approve</td>
                  <td>:</td>
                  <td>
                    {modalData?.from_employee_details?.need_to_approve_by}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="mt-3 border-top">
            <h3 className="my-2">From Details</h3>

            <table>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>:</td>
                  <td>{modalData?.from_employee_details?.name}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>:</td>
                  <td>{modalData?.from_employee_details?.phone}</td>
                </tr>
                <tr>
                  <td>Employee ID</td>
                  <td>:</td>
                  <td>{modalData?.from_employee_details?.emp_id}</td>
                </tr>
                <tr>
                  <td>User Type</td>
                  <td>:</td>
                  <td>{modalData?.from_employee_details?.user_type}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>:</td>
                  <td>{modalData?.from_employee_details?.email}</td>
                </tr>
              </tbody>
            </table>
            </div>
          <div className="mt-3 border-top">
            <h3 className="my-2">To Details</h3>

            <table>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>:</td>
                  <td>{modalData?.to_employee_details?.name}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>:</td>
                  <td>{modalData?.to_employee_details?.phone}</td>
                </tr>
                <tr>
                  <td>Employee ID</td>
                  <td>:</td>
                  <td>{modalData?.to_employee_details?.emp_id}</td>
                </tr>
                <tr>
                  <td>User Type</td>
                  <td>:</td>
                  <td>{modalData?.to_employee_details?.user_type}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>:</td>
                  <td>{modalData?.to_employee_details?.email}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TermsAndConditions;
