import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import DataGrid from 'react-data-grid';
import moment from "moment";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useLocation, useNavigate } from "react-router-dom";
import { MdDateRange } from "react-icons/md";
import Pagination from "react-bootstrap/Pagination";
import { Container, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./StockTransfer.scss";
import { getBrands, getModals, getStores } from "../redux/reducers/rules";
import { getStockList, getTrack, setStockData, stockApprove } from "../redux/reducers/stocktranfer";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from 'multiselect-react-dropdown';
import Select from 'react-select';
import { BsThreeDotsVertical } from "react-icons/bs";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { GrStatusGood } from "react-icons/gr";
import { BsThreeDots } from "react-icons/bs";
import { FaQuestion } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { CiCirclePlus } from "react-icons/ci";
import { FaSortAmountDownAlt } from "react-icons/fa";
import Loader from "../components/loader/loader";
import noData from "../assets/no_data.png";
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";



export default function StockTransfer() {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [dammi, setShowDammi] = useState(false);
    const [modalData, setModalData] = useState({});

    //ravi code starts here
    let location = useLocation();
    let params = new URLSearchParams(location.search);
    // Getting the query parameters with default values

    let id = params.get ('id') || 'defaultId';
    let empid = params.get ('empid') || 'defaultId';
    let store = params.get('store') || 'defaultId';
    let approver = params.get ('approver') || 'defaultId';

    const storeList = useSelector((state) => state.rules.storeList);
    const modalList = useSelector((state) => state.rules.modalList);
    const brandList = useSelector((state) => state.rules.brandList);
    const allTransferList = useSelector((state) => state.stocktransfer.allTransferList);
    const trackingDetails = useSelector((state) => state.stocktransfer.trackingDetails);
    const approve_response = useSelector((state) => state.stocktransfer.approve_response);
    const loader = useSelector((state) => state.stocktransfer.isLoader);
    const [selectedBrandModelList, setSelectedBrandModelList] = useState(modalList);
    const [selectedBrand, setSelectedBrand] = useState()
    const [selectedModal, setSelectedModal] = useState()
    const [selectedStore, setSelectedStore] = useState()
    const [selectedStatus, setSelectedStatus] = useState();
    const [selectedDays,setSelectedDays]=useState( { value: "7", label: "7 Days" });
    console.log("selectedDays",selectedDays)
    const [showFilter, setShowFilter] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false)
    const [checked, setChecked] = useState(false);
    const [customDateRange,setCustomDateRange]=useState(false)
    const navigate=useNavigate();
    const todayDate = new Date();
    todayDate.setDate(todayDate.getDate() - 1);
    const [customStartDate, setCustomStartDate] = useState(
        moment(new Date(todayDate)).format("YYYY/MM/DD")
      );
      const [customEndDate, setCustomEndDate] = useState(
        moment(new Date(todayDate)).format("YYYY/MM/DD")
      );

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };

    // let empid = "HM0184"
    // let approver = "asm"
    // let id = '65d8668d9af752c5bde1c125';
    // let store =  [
    //         "ADILABAD",
    //         "ATTAPUR",
    //         "BANJARAHILLS",
    //         "DILSUKHNAGAR",
    //         "KARIMNAGAR",
    //         "NIRMAL",
    //         "NIZAMABAD",
    //         "SIDDIPET",
    //         "CHAITANYAPURI",
    //         "GODHAVARIKHANI",
    //         "JAGTIAL",
    //         "LANGERHOUSE 2",
    //         "SAROORNAGAR",
    //         "VANASTALIPURAM",
    //         "NIZAMABAD 2",
    //          "AMEERPET"
    //     ]

    //ravi code ends here
    //ravi code
    console.log(empid, "empidempid")
    const statusOptions = [
        { value: "APPROVED", label: "APPROVED" },
        { value: "REJECTED", label: "REJECTED" },
        { value: "MY_APPROVAL", label: "TO BE APPROVED" },
    ]
    const DaysOptions = [
        { value: "7", label: "7 Days" },
        { value: "30", label: "30 Days" },
    ]
    useEffect(() => {
        dispatch(getStores())
        dispatch(getBrands()).then((res) => {
            let brandsList = [];
            res?.payload?.forEach((eachItem) => {
                brandsList.push(eachItem.value);
            });
            dispatch(getModals({ brandName: brandsList })).then((modals) => {
                console.log("modals", modals);
                setSelectedBrandModelList(modals.payload)
            });
        });
    }, [])
    const handleSelectedBrand = (brand) => {
        setSelectedBrand(brand)
        const result = modalList?.filter((word) => word.brand == brand?.value);
        setSelectedBrandModelList(result);
    }
    const handleSelectedModal = (modal) => {
        setSelectedModal(modal)
    }
    const handleSelectedStore = (store) => {
        setSelectedStore(store)
    }
    useEffect(() => {
        let params = { id: id, model: selectedModal, brand: selectedBrand, store_name: selectedStore, status: selectedStatus }
        dispatch(getStockList({params:params,days:selectedDays?.value,type:'days'}))
    }, [selectedStore, selectedBrand, selectedModal, selectedStatus, approve_response,selectedDays])

    const showStatusTree = (id) => {
        dispatch(getTrack(id))
        navigate('/trackingdetails')
    }

    const handleChange = ([newStartDate, newEndDate]) => {
        setCustomStartDate(newStartDate);
        setCustomEndDate(newEndDate);
      };
    
    const TermsAndConditionsNavigtor=(data)=>{
        navigate(`/termsandconditons`)
        // setModalData(stock);
        dispatch(setStockData({data:data,approver:approver,store:store,empid:empid}))
    }

    const navigationToTrackDetails=()=>{
        navigate(`/trackingdetails`)
 
    }
    const statusIcon = (status) => {
        if (status == "PENDING") {
            return <BsThreeDots />
        }
        if (status == "OPTIONAL") {
            return <FaQuestion />
        }
        if (status == "REJECTED") {
            return <IoMdClose />
        }
        if (status == "APPROVED") {
            return <GrStatusGood />
        }
        if (status == "CREATED") {
            return <CiCirclePlus />
        }
    }

    const statusBackgroundColor = (status) => {
        if (status == "PENDING") {
            return (
                {
                    background: "#efa742",
                    color: "#fff"
                }
            )
        }
        if (status == "OPTIONAL") {
            return (
                { background: "#2d4962", color: "#fff" }
            )
        }
        if (status == "REJECTED") {
            return { background: "#730405", color: "#fff" }
        }
        if (status == "APPROVED") {
            return (
                { background: "#15a05c", color: "#fff" }
            )
        }
        if (status == "CREATED") {
            return { background: "#3f89c7", color: "#fff" }
        }
    }

    const termsConditionCheck = () => {
        if (checked) {
            setShowModal(true);
            // scrollToTop()
            setShowTermsModal(false);
            setChecked(false)
        }
        else {
            toast.error("Please Agree Terms and Conditions")
        }

    }

    const approveAndReject = (id, status) => {
        dispatch(stockApprove({
            action: status,
            approver: approver,
            empid: empid,
            stockid: id,
            store: store
        })).then((res) => {
            setShowModal(false)
        })
    }

    const onDateSelection=(status)=>{
        if(status.value=="custom"){
            
        }
        if(status?.value=='7'||status?.value=='30'){
            setSelectedDays(status)
        }
    }
    console.log(parseInt(modalData?.approved_asm), "modalData?.approved_asm.parseInt()")
    return (
        <>
            <section className="mt-2 exchange_products">
                <Container>
                    <ToastContainer />
                    <Row className="justify-content-center">
                        <Col sm={4}>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <h1 className="m-0">Stock Transfer</h1>
                        <div className="filter-icon" onClick={() => setShowFilter(!showFilter)}>
                            <FaSortAmountDownAlt />
                        </div>
                    </div>
                    

                    {showFilter && <Row>
                        <Col sm={6} className="mt-2 mt-md-0">
                            <div className="zindex-1">                            <Select
                                options={brandList}
                                onChange={handleSelectedBrand}
                                value={selectedBrand}
                                placeholder={"select brand..."}
                                isClearable={true}
                            />
                            </div>


                        </Col>
                        <Col sm={6} className="mt-2 mt-sm-0">
                        <div className="zindex-2">
                            <Select
                                options={selectedBrandModelList}
                                onChange={handleSelectedModal}
                                value={selectedModal}
                                placeholder={"select model..."}
                                isClearable={true}
                            />
                            </div>
                        </Col>
                        <Col sm={6} className="mt-2 mt-sm-2">
                        <div className="zindex-3">
                            <Select
                                options={storeList}
                                onChange={handleSelectedStore}
                                value={selectedStore}
                                placeholder={"select store..."}
                                isClearable={true}
                            />
                            </div>

                        </Col>
                        <Col sm={6} className="mt-2 mt-sm-2">
                        <div className="zindex-4">
                            <Select
                                options={statusOptions}
                                onChange={(status) => { setSelectedStatus(status) }}
                                value={selectedStatus}
                                placeholder={"select status..."}
                                isClearable={true}
                            />
                            </div>

                        </Col>
                        <Col sm={6} className="mt-2 mt-sm-2">
                        <div className="zindex-4">
                            <Select
                                options={DaysOptions}
                                onChange={(status) => onDateSelection(status)}
                                value={selectedDays}
                                placeholder={"select Days..."}
                                isClearable={true}
                                defaultValue={"7 Days"}

                            />
                            </div>

                        </Col>
                        {/* <Col md={3} sm={12}>
                              <div className="d-flex align-items-center w-100 gap-2 selectdate mb-2">
                                <label>Select Dates :</label>
                                <div className="position-relative z-1">
                                  <DatePicker
                                    selected={customStartDate}
                                    onChange={handleChange}
                                    selectsRange
                                    isClearable
                                    startDate={customStartDate}
                                    endDate={customEndDate}
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date(2024, 5)}
                                  />
                                </div>
                              </div>
                            </Col> */}
                    </Row>}
                    <Row className="zindex--1">
                        {loader ? (<Loader />) : (<>
                            {allTransferList?.length > 0 ? (
                                <>
                                    {allTransferList?.map((stock, id) => {
                                        console.log('stock?.need_to_approve_by == "asm" && stock?.need_to_approve_by == approver && !store.includes(stock?.approved_asm)',(stock?.need_to_approve_by == "asm" && stock?.need_to_approve_by == approver && !store.includes(stock?.approved_asm)))
                                        return (
                                            <Col sm={12}>
                                                <Card className="mt-3" key={id}>
                                                    <ListGroup variant="flush">
                                                        <ListGroup.Item><label>Brand:</label><p>{stock?.brand}</p></ListGroup.Item>
                                                        <ListGroup.Item><label>Model:</label><p>{stock?.model}</p></ListGroup.Item>
                                                        <ListGroup.Item><label>Status:</label><p>{stock?.status}</p></ListGroup.Item>
                                                        <ListGroup.Item><label>From StoreName:</label><p>{stock?.from_store_name}</p></ListGroup.Item>
                                                        <ListGroup.Item><label>To StoreName:</label><p>{stock?.to_store_name}</p></ListGroup.Item>
                                                        <ListGroup.Item><label>Quantity:</label><p>{stock?.quantity}</p></ListGroup.Item>
                                                        <ListGroup.Item><label>Created On:</label><p>{moment(stock?.created_at).format("lll")}</p></ListGroup.Item>
                                                        <div className="my-2 mx-2 d-flex justify-content-between">
                                                            {
                                                                (stock?.need_to_approve_by == "asm" && stock?.need_to_approve_by == approver && !store.includes(stock?.approved_asm)) || (stock?.need_to_approve_by != "asm" && stock?.need_to_approve_by == approver)?<Button size="md" onClick={()=>TermsAndConditionsNavigtor(stock)}>View</Button>:<Button size="md" disabled={true} style={{opacity:0.5}}>View</Button>
                                                            }
                                                             {/* <Button size="md" onClick={() => { setModalData(stock); setShowTermsModal(true) }}>View</Button> */}
                                                            <Button size="md" onClick={() => showStatusTree(stock?.id)}><BsThreeDotsVertical /></Button>
                                                        </div>
                                                    </ListGroup>
                                                </Card>
                                            </Col>
                                        )
                                    })}
                                </>
                            ) : (
                                <div className="d-flex align-items-center justify-content-center nodatafound-h-ctrl">
                                    <img src={noData} alt="no data found" title="no data found" />
                                </div>
                            )}
                        </>
                        )}
                    </Row>
                    {/* <Pagination className="justify-content-center mt-5 position-fixed bottom-0 left-0 bg-white p-3 mb-0 w-100">
                        {Array.from({ length: totalActivePages }).map((_, index) => {
                            const pageNumber = index + 1;
                            const isCurrentPage = pageNumber === activePage;

                            if (pageNumber === 1 || pageNumber === totalActivePages || Math.abs(pageNumber - activePage) <= 2) {
                                return (
                                    <Pagination.Item
                                        key={pageNumber}
                                        active={isCurrentPage}
                                        onClick={() => handleActivePageChange(pageNumber)}
                                        style={isCurrentPage ? { backgroundColor: "#FB9013", color: "#FB9013" } : {}}
                                    >
                                        {pageNumber}
                                    </Pagination.Item>
                                );
                            } else if (Math.abs(pageNumber - activePage) === 3) {
                                return <Pagination.Ellipsis key={pageNumber} />;
                            } else {
                                return null;
                            }
                        })}
                    </Pagination> */}
                    </Col>
                    </Row>
                </Container>
            </section>
            <Modal centered scrollable={true} show={showTermsModal} onHide={() => { setShowTermsModal(false) }}  className="" size="md" backdrop="static" keyboard={false}>
                <Modal.Header >
                    <Modal.Title className="ps-2">Terms & Conditions</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-3">
                    <div className="mb-3">
                        <ul>
                            <li>Transport of stock from store to store in the same district is prohibited without proper authorization. Approval from the respective ASM of the store is required and if any damage occurred or if the product goes missing then during the transit then ASM’s of such stores will be held responsible.</li>
                            <li>Transport of stock between stores located in different districts is prohibited without proper authorization. Approval from the respective ASM of the store is required and if any damage occurred or if the product goes missing then during the transit then ASM’s of such stores will be held responsible.</li>
                        </ul>


                    </div>

                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                        <Form>
                            <Form.Check // prettier-ignore
                                type={"checkbox"}
                                id={`default-checkbox`}
                                label={`I agree`}
                                checked={checked}
                                onChange={() => setChecked(prev => !prev)}
                            />

                        </Form>
            
            <button onClick={termsConditionCheck} disabled={!checked} className="btn btn-primary">Proceed</button>
                </Modal.Footer>
            </Modal>
            <Modal show={showModal} onHide={() => { setShowModal(false); setModalData({}) }} className="exchange_modal" size="md">
                <Modal.Header closeButton>
                    <Modal.Title className="ps-2">Transfer Details</Modal.Title>
                    {(modalData?.need_to_approve_by == "asm" && modalData?.need_to_approve_by == approver && !store.includes(modalData?.approved_asm)) || (modalData?.need_to_approve_by != "asm" && modalData?.need_to_approve_by == approver) ?
                        <> <Button className="mx-2 ms-auto" size="sm" variant="danger" onClick={() => approveAndReject(modalData?.id, "reject")}>
                            Reject
                        </Button>
                            <Button className="btn btn-primary btn-md" size="sm" onClick={() => approveAndReject(modalData?.id, "approve")}>
                                Approve
                            </Button></> : null}
                </Modal.Header>
                <Modal.Body className="p-3">
                    <div className="mb-3">
                        {/* <h3 className="mb-2">Old Products</h3> */}
                        <div><label>Brand:</label><p>{modalData?.brand}</p></div>
                        <div><label>Model:</label><p>{modalData?.model}</p></div>
                        <div><label>Status:</label><p>{modalData?.status}</p></div>
                        <div><label>From StoreName:</label><p>{modalData?.from_store_name}</p></div>
                        <div><label>To StoreName:</label><p>{modalData?.to_store_name}</p></div>
                        <div><label>Quantity:</label><p>{modalData?.quantity}</p></div>
                        <div><label>Need to Approve:</label><p>{modalData?.from_employee_details?.need_to_approve_by}</p></div>
                        <h3 className="mb-2">From Details</h3>
                        <div><label>Name</label><p>{modalData?.from_employee_details?.name}</p></div>
                        <div><label>Phone:</label><p>{modalData?.from_employee_details?.phone}</p></div>
                        <div><label>Employee ID:</label><p>{modalData?.from_employee_details?.emp_id}</p></div>
                        <div><label>User Type:</label><p>{modalData?.from_employee_details?.user_type}</p></div>
                        <div><label>Email:</label><p>{modalData?.from_employee_details?.email}</p></div>
                    </div>
                    <div className="mt-3 border-top">
                        <h3 className="mt-3 mb-2">To Details</h3>
                        <div><label>Name</label><p>{modalData?.to_employee_details?.name}</p></div>
                        <div><label>Phone:</label><p>{modalData?.to_employee_details?.phone}</p></div>
                        <div><label>Employee ID:</label><p>{modalData?.to_employee_details?.emp_id}</p></div>
                        <div><label>User Type:</label><p>{modalData?.to_employee_details?.user_type}</p></div>
                        <div><label>Email:</label><p>{modalData?.to_employee_details?.email}</p></div>
                    </div>

                </Modal.Body>
            </Modal>
            <Modal  scrollable={true} show={dammi} onHide={() => { setShowDammi(false); }} size="lg" className="order_status exchange_modal">
                <Modal.Header closeButton>
                    <Modal.Title className="ps-2">Order Status</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-3">
                    <VerticalTimeline>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            // contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="STORE-1"
                            iconStyle={statusBackgroundColor(trackingDetails?.from_store?.status)}
                            icon={statusIcon(trackingDetails?.from_store?.status)}
                        >
                            <div className="ribbon"><span style={statusBackgroundColor(trackingDetails?.from_store?.status)}>{trackingDetails?.from_store?.status}</span></div>
                            <h3 className="vertical-timeline-element-title">{trackingDetails?.from_store?.name}</h3>
                            <h4 className="vertical-timeline-element-subtitle">{moment(trackingDetails?.from_store?.created_at).format("lll")}</h4>
                            {/* <p>Creative Direction, User Experience, Visual Design, Project Management, Team Leading</p> */}
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            date="STORE-2"
                            iconStyle={statusBackgroundColor(trackingDetails?.to_store?.status)}
                            icon={statusIcon(trackingDetails?.to_store?.status)}
                        >
                            <div className="ribbon"><span style={statusBackgroundColor(trackingDetails?.to_store?.status)}>{trackingDetails?.to_store?.status}</span></div>
                            <h3 className="vertical-timeline-element-title">{trackingDetails?.to_store?.name}</h3>
                            {(trackingDetails?.to_store?.status != "PENDING" && trackingDetails?.to_store?.status != "OPTIONAL") ? <h4 className="vertical-timeline-element-subtitle">{moment(trackingDetails?.to_store?.created_at).format("lll")}</h4> : <h4 className="vertical-timeline-element-subtitle">Waiting for Approval</h4>}
                            {/* <p>Creative Direction, User Experience, Visual Design, SEO, Online Marketing</p> */}
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            date="ASM-1"
                            iconStyle={statusBackgroundColor(trackingDetails?.asm_1?.status)}
                            icon={statusIcon(trackingDetails?.asm_1?.status)}
                        >
                            <div className="ribbon"><span style={statusBackgroundColor(trackingDetails?.asm_1?.status)}>{trackingDetails?.asm_1?.status}</span></div>
                            <h3 className="vertical-timeline-element-title">{trackingDetails?.asm_1?.name}</h3>
                            {(trackingDetails?.asm_1?.status != "PENDING" && trackingDetails?.asm_1?.status != "OPTIONAL") ? <h4 className="vertical-timeline-element-subtitle">{moment(trackingDetails?.asm_1?.created_at).format("lll")}</h4 > : <h4 className="vertical-timeline-element-subtitle">Waiting for Approval</h4>}
                            {/* <p> User Experience, Visual Design</p> */}
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            date="ASM-2"
                            iconStyle={statusBackgroundColor(trackingDetails?.asm_2?.status)}
                            icon={statusIcon(trackingDetails?.asm_2?.status)}
                        >
                            <div className="ribbon"><span style={statusBackgroundColor(trackingDetails?.asm_2?.status)}>{trackingDetails?.asm_2?.status}</span></div>
                            <h3 className="vertical-timeline-element-title">{trackingDetails?.asm_2?.name}</h3>
                            {(trackingDetails?.asm_2?.status != "PENDING" && trackingDetails?.asm_2?.status != "OPTIONAL") ? <h4 className="vertical-timeline-element-subtitle">{moment(trackingDetails?.asm_2?.created_at).format("lll")}</h4> : <h4 className="vertical-timeline-element-subtitle">Waiting for Approval</h4>}
                            {/* <p>User Experience, Visual Design</p> */}
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            date="STATE HEAD"
                            iconStyle={statusBackgroundColor(trackingDetails?.statehead?.status)}
                            icon={statusIcon(trackingDetails?.statehead?.status)}
                        >
                            <div className="ribbon"><span style={statusBackgroundColor(trackingDetails?.statehead?.status)}>{trackingDetails?.statehead?.status}</span></div>
                            <h3 className="vertical-timeline-element-title">{trackingDetails?.statehead?.name}</h3>
                            {(trackingDetails?.statehead?.status != "PENDING" && trackingDetails?.statehead?.status != "OPTIONAL") ? <h4 className="vertical-timeline-element-subtitle">{moment(trackingDetails?.statehead?.created_at).format("lll")}</h4> : <h4 className="vertical-timeline-element-subtitle">Waiting for Approval</h4>}
                            {/* <p>Strategy, Social Media</p> */}
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--education position-relative"
                            date="CORPORATE"
                            iconStyle={statusBackgroundColor(trackingDetails?.corporate?.status)}
                            icon={statusIcon(trackingDetails?.corporate?.status)}
                        >
                            <div className="ribbon"><span style={statusBackgroundColor(trackingDetails?.corporate?.status)}>{trackingDetails?.corporate?.status}</span></div>
                            <h3 className="vertical-timeline-element-title">{trackingDetails?.corporate?.name}</h3>
                            {(trackingDetails?.corporate?.status != "PENDING" && trackingDetails?.corporate?.status != "OPTIONAL") ? <h4 className="vertical-timeline-element-subtitle">{moment(trackingDetails?.corporate?.created_at).format("lll")}</h4> : <h4 className="vertical-timeline-element-subtitle">Waiting for Approval</h4>}
                            {/* <p>Creative Direction, User Experience, Visual Design</p> */}
                        </VerticalTimelineElement>
                    </VerticalTimeline>
                </Modal.Body>
            </Modal>

        </>
    )
}